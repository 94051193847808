<template>
  <div id="root">
    <input
      id="LANTERN_API_KEY"
      name="LANTERN_API_KEY"
      type="hidden"
      value="z7t3r4e9872mnds8smns7fbsmjs623nds"
    />
    <input
      id="CONTENT_API_KEY"
      name="CONTENT_API_KEY"
      type="hidden"
      value="z7t3r4e9872mn65ty47dt3erstyw543i9"
    />
    <input
      id="LANTERN_APP_KEY"
      name="LANTERN_APP_KEY"
      type="hidden"
      value="VentureStarEmail"
    />

    <!-- <button @click="sendEmails">test emails</button> -->
    <dxButton :visible="false" text="Show message" @click="showMessage">
    </dxButton>
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
        <div class="content">
          <router-view></router-view>
        </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script setup>
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { useStore } from "vuex";
import { reloadLoginDetailsFromLocalStorage } from "../../core/controls/Helpers/reloadLoginDetails";
import { useMyMixin } from "./mixins/connectToHubs";
const { getJWT, connectToHubs } = useMyMixin();

import { DxButton } from "devextreme-vue/button";

// import email_payload from '../../../../labs/email_payload.json';

import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
const store = useStore();

// function sendEmails() {
//   console.log(email_payload);

//   store.dispatch('extractTagsFromEmails', email_payload);
// }

// import { createConnection } from "../../core/signalr/clients/EmailDataHub";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

async function getDetes() {
  await reloadLoginDetailsFromLocalStorage(store);
}

getDetes();

// reloadLoginDetailsFromLocalStorage(store);

const getJWTThenConnectToHubs = async () => {
  if (!store.getters.getIsUserLoggedIn) return;
  await getJWT();
  connectToHubs();
};

getJWTThenConnectToHubs();

const vm = getCurrentInstance();

const title = vm.proxy.$appInfo.title;
const screen = reactive({ getScreenSizeInfo: {} });
screen.getScreenSizeInfo = getScreenSizeInfo();

function screenSizeChanged() {
  screen.getScreenSizeInfo = getScreenSizeInfo();
}

onMounted(() => {
  subscribe(screenSizeChanged);
});

onBeforeUnmount(() => {
  unsubscribe(screenSizeChanged);
});

const cssClasses = computed(() => {
  return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
});

// const createHubProxy = (proxy) => {
//   console.log("🚀 ~ createHubProxy ~ proxy:", proxy);
//   const { myHub } = createConnection(
//     store.getters.modelHub,
//     proxy,
//     store.getters.temporaryToken
//   );

//   return myHub;
// };

// async function getJWT() {
//   try {
//     await store.dispatch("emailSync");
//     await store.dispatch("getIntelligentEmailAddress");

//     const EmailDataHub = createHubProxy("EmailDataHub");
//     EmailDataHub.on("update", (p) => {
//       store.commit("addEmail", p);
//       store.commit("extractTagsFromEmails", [p]);
//       showToast("Email", "✉️");
//     });
//     createHubProxy("CalendarEventHub");
//     const DocumentHub = createHubProxy("DocumentHub");
//     DocumentHub.on("update", (p) => {
//       console.log('DocumentHub');
//       console.log(p);
//       if (p.DocumentFormat === 4 || p.DocumentFormat === 5 || p.DocumentFormat === 6){
//         showToast("Data", "📊");
//       }
//       if (p.DocumentFormat === 7){
//         showToast("PDF", "📄");
//       }
//       if (p.DocumentFormat >=15 && p.DocumentFormat <= 19){
//         showToast("Image", "📸");
//       }
//     });
//     const EmailKBDataHub = createHubProxy("EmailKBDataHub");
//     EmailKBDataHub.on("update", (p) => {
//       console.log(p);
//       p.Items.forEach((item) => {
//         store.commit("addToEmailKnowledgeBase", item);
//       });
//     });
// createHubProxy("GroupHub");
// createHubProxy("JobHub");
// createHubProxy("ProjectHub");
// createHubProxy("WebKnowledgeBaseHub");
//   } catch (error) {
//     console.error("Error in fetchData:", error);
//   }
// }

// async function showMessage()
// {
//   showToast("PDF", "📄");
//   showToast("Email", "✉️");
//   showToast("iCal", "📅");
//   showToast("Data", "📊");
//   showToast("Group", "👥");
//   showToast("Link", "🌐");
//   showToast("User", "🧑");
//   showToast("Project", "📝");
//   showToast("Venture", "🏢");
//   showToast("Image", "📸");

//   for (let index = 0; index < 13; index++) {
//     showToast("Email", "✉️");
//     await new Promise(r => setTimeout(r, 1000));
//   }
// }

// function showToast(message, icon){
//           notify(
//                 {
//                     message: icon + " " + message,
//                     height: 45,
//                     width: 110,
//                     minWidth: 110,
//                     type: 'info',
//                     displayTime: 3500,
//                     animation: {
//                       show: {
//                         type: 'fade', duration: 400, from: 0, to: 1,
//                       },
//                       hide: { type: 'fade', duration: 40, to: 0 },
//                     }
//                 },
//                 { position: "bottom right", direction: "up-push" }
//             );
// }

// getJWT();
// return {
//   title,
//   screen,
//   cssClasses,
// };
//   },
// };
</script>

<style lang="scss">
// @use 'devextreme/scss/bundles/dx.carmine.scss';

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: white;
  // background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
