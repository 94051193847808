export default  {
    setPersonalCalendar(state, group) {
      state.personalCalendar = group;
    },
    setEvents(state, events) {
      console.log(events);
      state.events = events;
    },

    setCurrentGroupMembers(state, members) {
      state.currentGroupMembers = members;
    },

    // setUsersGroups(state, groups) {
    //   state.groupList = groups;
    // },
    // removeSelectedGroup(state, groupID) {
    //   state.groupList = state.groupList.filter(group => group.groupID !== groupID);
    // },
    // removeSelectedUserFromGroup(state, userAndGroupID) {
    //   const selectedGroupIndex = state.groupList.findIndex(group => group.groupID === userAndGroupID.groupID);
    //   console.log(selectedGroupIndex);
    //   console.log(state.groupList[selectedGroupIndex]);
    //   state.groupList[selectedGroupIndex].groupMembers = state.groupList[selectedGroupIndex].groupMembers.filter(member => member.user !== userAndGroupID.user)
    // },
    setSelectedGroup(state, group) {
      state.currentGroup.text = group.text;
      state.currentGroup.id = (group.id).toString();
      state.currentGroup.color = group.color;
      state.currentGroup.discipline = group.discipline;
      state.currentGroup.owner = group.owner;
      state.currentGroup.members = group.members;
      state.currentGroup.allMembers = group.allMembers;
    },
    // addGroupEventsToGroup(state, events) {
    //   if (events[0]) {
    //     const groupIndex = state.groupList.findIndex(group => group.groupID == events[0].userID);
    //     state.groupList[groupIndex].events = events
    //   }
    // },
    newSetGroupEvents(state, events) {
      console.log(events);
      if (events) state.groupEvents = events;
    },
    setAllCalsAndEvents(state, payload) {
      state.allCalsAndEvents = payload;
    },
    addEventToCal(state, eventDetails) {
      // state.groupEvents.push(eventDetails.event);
      state.allCalsAndEvents[eventDetails.calUID].events.push(eventDetails.event);
    },
  
    // setGroupEvents(state, events ) {
    //   console.log(events);
    //   console.log(state.groupList);
    //   if (events[0]) {

    //   const groupIndex = state.groupList.findIndex(group => group.groupID == events[0].userID);
    //   if (groupIndex !== -1) {
    //     console.log(groupIndex);
    //     state.groupList[groupIndex].events = events
    //     }
    //   } 
    //   state.groupEvents = events;
    // },
    setGroupCalUID(state, UIDandName) {
      state.currentGroup.text = UIDandName.text
      state.currentGroup.calUID = UIDandName.UID;
      state.currentGroup.emoji = UIDandName.emoji;
    },
    setUserUID(state, UID) {
      state.tymeToolUser.calUID = UID;
    },
    setUserEmail(state, email) {
      state.tymeToolUser.emailAddress = email;
    },

    setLinkedEvents(state, events) {
      state.linkedEvents = events
    },
    setEventsFromAPI(state, events) {
      state.eventsFromAPI = events;
    },







    ////////////////////////

    
















  }