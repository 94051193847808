import { createStore } from "vuex";
import Emails from "../../../core/controls/emails/index";
import auth from "../../../core/controls/auth/index";
import Groups from "../../../core/controls/groupsAndCals/groups";
import Calendars from "../../../core/controls/groupsAndCals/tymetool";
import Documents from "./Documents";
import { useMyMixin } from '../mixins/connectToHubs';
const { getJWT, connectToHubs } = useMyMixin();
import router from '../router';
// import { showToast } from "../../../core/utils/showToast";
import { showToast } from "../../../core/utils/showToast";

// import UserJouneys from '../../../../core/controls/userJourneys/index';
// import emailPayload from "../../../../../labs/email_payload.json";
// import knowledgeBase from "../../../../../labs/flatEmailTreeView.json";

export default createStore({
  state: { needsAccessCode: false },
  getters: {
    needsAccessCode(state) {
      return state.needsAccessCode
    }
  },
  actions: {
    navigateToLoginForm() {
      showToast("Please log in again", "");
      router.push('/login-form');
    },
    async applicationSpecificStartup(context) {
          await getJWT();
           connectToHubs();

      return {
        isOk: true,
        data: context.getters.getUser.email
      }
    },
    // async register(context) {

    //   const user ={
    //     Password: "lanternsoft",
    //     ConfirmPassword: "lanternsoft",
    //     UserName: "zack",
    //     EmailAddress: "zacky97@gmail.com"
    //   }

    //   console.log(context.getters.apiKey);

    //   let res = await fetch('http://localhost:1242/Register', {
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       ApiKey: context.getters.apiKey,
    //       // AccessCode: formData.accessCode,
    //     },
    //     method: "POST",
    //     body: JSON.stringify(user),
    //   })

    //   const json = await res.json();

    //   console.log(json);
    // },
    async fetchAllEmails() {

      //   THE CODE TO CALL EMAILS FROM THE NEW ENDPOINT  

      //   let res = await fetch("fetch from the new email endpoint", {});

      //   let emails = await res.json();

      //   emails.forEach((email) => {
      //     context.commit("addEmail", email);
      //   });

    },
    fetchKnowledgeBase() {
      //   THE CODE TO CALL THE KNOWLEDGE BASE FROM THE NEW ENDPOINT  

      //   let res = await fetch("fetch from the new KB endpoint", {});

      //   let kb = await res.json();

    },
  },
  modules: {
    Emails,
    auth,
    Groups,
    Calendars,
    Documents
    // UserJouneys
  },
});
