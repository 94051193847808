import actions from './actions';

export default {
    state() {
        return {
            someValue: false,
            groupList: [],
        }
    },
    getters: {
        getGroups(state) {
            return state.groupList;
          },
    },
    mutations: {
        setUsersGroups(state, groups) {
            state.groupList = groups;
        },
    },
    actions
}