//EXAMPLE HIDDEN INPUTS NEEDED ON THE PAGE LOADED
//<input id="LANTERN_API_KEY" name="LANTERN_API_KEY" type="hidden" value="">
//<input id="LANTERN_APP_KEY" name="LANTERN_APP_KEY" type="hidden" value="">

export default {

    tester() {
        console.log('yep got here');
    },

    sendMessage(name, email, message) {

        //these elements added in the host pages directly
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;

        fetch("http://52.178.186.85:7775/SendMessage/" + appKey + "/" + name + "/" + email + "/" + message,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey
                },
                method: "POST"
            })
            // eslint-disable-next-line no-unused-vars
            .then(function (res) { alert('Thank you - message sent - we will be in contact soon') })
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) { alert('Oops. Something went wrong.') });
    },

    subscribeToMailingList(email) {

        //these elements added in the host pages directly
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;

        fetch("http://52.178.186.85:7775/JoinMailingList/" + appKey + "/" + email + "/General",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey
                },
                method: "POST"
            })
            // eslint-disable-next-line no-unused-vars
            .then(function (res) { alert('Thank you - you are subscribed') })
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) { alert('Oops. Something went wrong. ') });
    },


    pageHit(content) {

        //these elements added in the host pages directly
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;

        fetch("http://52.178.186.85:7775/PageHit/" + appKey + "/" + content,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'ApiKey': apiKey
                },
                method: "GET"
            })
            // eslint-disable-next-line no-unused-vars
            .then(function (res) {
                //alert('hit' + res);
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) {
                //alert('error' + res);
            });
    },

    getMailingList() {

        //these elements added in the host pages directly
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;
        const result = [];

        fetch("http://localhost:7775/GetMailingList/" + appKey, 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey
                    },
                    method: "GET"
                })
            // eslint-disable-next-line no-unused-vars
            .then(
                response => {

                    if (response.ok) {

                        return response.json();

                    } else {
                        alert("Server returned " + response.status + " : " + response.statusText);
                    }
                },
                reject => alert('No data available - please try again soon - ' + reject)
            )
            .then(json => {

                    for (var email in json) {
                        result.push(email);
                    }
                }

            ) 
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) {
                alert('error' + res);
            });

        return result;
    },

    getMessages() {
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;
        const result = [];

        fetch("http://localhost:7775/GetMessages/" + appKey, 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey
                    },
                    method: "GET"
                })
            // eslint-disable-next-line no-unused-vars
            .then(
                response => {

                    if (response.ok) {

                        return response.json();

                    } else {
                        alert("Server returned " + response.status + " : " + response.statusText);
                    }
                },
                reject => alert('No data available - please try again soon - ' + reject)
            )
            .then(json => {

                    for (var message in json) {
                        result.push(message);
                    }
                }

            ) 
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) {
                alert('error' + res);
            });

        return result;
    },

    getApps() {
        console.log('trying to get some appz')
        let apiKey = 'z7t3r4e9872mnds8smns7fbsmjs623nds';
        let appKey = 'HealthDataUK'
        // let apiKey = document.getElementById("LANTERN_API_KEY").value;
        // let appKey = document.getElementById("LANTERN_APP_KEY").value;
        const result = [];

        fetch("http://52.178.186.85:7775/GetApps/" + appKey, 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey
                    },
                    method: "GET"
                })
            // eslint-disable-next-line no-unused-vars
            .then(
                response => {

                    if (response.ok) {

                        return response.json();

                    } else {
                        alert("Server returned " + response.status + " : " + response.statusText);
                    }
                },
                reject => alert('No data available - please try again soon - ' + reject)
            )
            .then(json => {

                    for (var app in json) {
                        result.push(app);
                    }
                }

            ) 
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) {
                alert('error' + res);
            });

        return result;
    },



    getAppHits() {
        let apiKey = document.getElementById("LANTERN_API_KEY").value;
        let appKey = document.getElementById("LANTERN_APP_KEY").value;
        const result = [];

        fetch("http://localhost:7775/GetAppHits/" + appKey, 
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey
                    },
                    method: "GET"
                })
            // eslint-disable-next-line no-unused-vars
            .then(
                response => {

                    if (response.ok) {

                        return response.json();

                    } else {
                        alert("Server returned " + response.status + " : " + response.statusText);
                    }
                },
                reject => alert('No data available - please try again soon - ' + reject)
            )
            .then(json => {

                    for (var appHit in json) {
                        result.push(appHit);
                    }
                }

            ) 
            // eslint-disable-next-line no-unused-vars
            .catch(function (res) {
                alert('error' + res);
            });

        return result;
    },
    
}

export async function getApps() {
    console.log('trying to get some appz');
    let apiKey = 'z7t3r4e9872mnds8smns7fbsmjs623nds';
    let appKey = 'HealthDataUK';
    const result = [];
    try {
    let response = await fetch("http://52.178.186.85:7775/GetApps/" + appKey,
            {
                headers: {
                    'Accept': 'application/json',
                    'ApiKey': apiKey
                },
                method: "GET"
            })
                if (!response) alert('No data available - please try again soon - ' + reject)

                if (!response.ok) return alert("Server returned " + response.status + " : " + response.statusText);
                
                const responseJSON = await response.json();
                result.push(responseJSON)
    }
        catch (res) {
            alert('error' + res);
    }
    return result;
}

export async function getAppHits() {
    let apiKey = 'z7t3r4e9872mnds8smns7fbsmjs623nds';
    let appKey = 'HealthDataUK'
    const result = [];
    try {
    let response = await fetch(`http://52.178.186.85:7775/GetAppHits/${appKey}/Home`,
    // let response = await fetch(`http://devops:7776/GetAppHits/${appKey}/${'CovidHistory'}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'ApiKey': apiKey
                },
                method: "GET"
            })
                if (!response) return alert('no response from app hits ' + reject)
                if (!response.ok) return alert("app hits: Server returned " + response.status + " : " + response.statusText);
                const responseJSON = await response.json();
                console.log(responseJSON);
                result.push(responseJSON)
    }
        catch (res) {
            alert('error' + res);
    }

    return result;
}

export async function getMailingList() {
    let apiKey = document.getElementById("LANTERN_API_KEY").getAttribute('value');
    let appKey = document.getElementById("LANTERN_APP_KEY").getAttribute('value');
    const result = [];
    try {
    let response = await fetch(`http://devops:7775/GetMailingList/${appKey}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'ApiKey': apiKey
                },
                method: "GET"
            })
                if (!response) return alert('No data available - please try again soon - ' + reject)
                if (!response.ok) return alert("Server returned " + response.status + " : " + response.statusText);
                const responseJSON = await response.json();
                result.push(responseJSON)
            }
            catch (res) {
                alert('error' + res);
            }
    return result;
}

export async function subscribeToMailingList(email) {

    //these elements added in the host pages directly
    let apiKey = document.getElementById("LANTERN_API_KEY").value;
    let appKey = document.getElementById("LANTERN_APP_KEY").value;

    fetch("http://52.178.186.85:7775/JoinMailingList/" + appKey + "/" + email + "/General",
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'ApiKey': apiKey
            },
            method: "POST"
        })
        // eslint-disable-next-line no-unused-vars
        .then(function (res) { alert('Thank you - you are subscribed') })
        // eslint-disable-next-line no-unused-vars
        .catch(function (res) { alert('Oops. Something went wrong. ') });
}

export async function getMessages() {
    let apiKey = document.getElementById("LANTERN_API_KEY").getAttribute('value');
    let appKey = document.getElementById("LANTERN_APP_KEY").getAttribute('value');
    const result = [];
    try {
    let response = await fetch(`http://devops:7775/GetMessages/${appKey}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'ApiKey': apiKey
                },
                method: "GET"
            })
                if (!response) return alert('No data available - please try again soon - ' + reject)
                if (!response.ok) return alert("Server returned " + response.status + " : " + response.statusText);
                const responseJSON = await response.json();
                result.push(responseJSON)
    }
        catch (res) {
            alert('error' + res);
    }
    return result;
}