<template>
  <!-- v-if="getGroupEvents.length > 0"  -->
  <div class="devex__calendar">
    <!-- <h2 v-if="getCurrentGroup" class="header__title">
      <span class="tymetool__icon"
        ><span>{{ getCurrentGroup.emoji }}</span></span
      >{{ getCurrentGroup.text }} &nbsp;
    </h2> -->
    <!-- {{ this.$store.getters.getGroupEvents }} -->
    <DxScheduler
      :data-source="this.$store.getters.getGroupEvents"
      :ref="'DxScheduler'"
      :time-zone="getTimezone"
      :current-date="currentDate"
      :height="550"
      :show-all-day-panel="true"
      :adaptivity-enabled="false"
      :first-day-of-week="1"
      :start-day-hour="8"
      :end-day-hour="18"
      :current-view="'month'"
      data-cell-template="dataCellTemplate"
      resource-cell-template="resourceCellTemplate"
      @appointment-adding="onAppointmentAdding"
      @appointment-added="onAppointmentAdded"
      @appointment-updated="onAppointmentUpdated"
      @appointment-deleted="onAppointmentDeleted"
    >
      <DxView type="agenda" :agenda-duration="30" />
      <DxView type="month" />
      <DxView type="week" />

      <DxResource
        :data-source="typeData"
        :allow-multiple="false"
        label="typeId"
        field-expr="typeId"
      />

      <template #resourceCellTemplate="{ data: getMembers }">
        <ResourceCell :groupuser="getMembers" />
      </template>

      <template #dataCellTemplate="{ data: someData }">
        <DataCell :cell-data="someData" :our-data="getEvents" />
      </template>
    </DxScheduler>
  </div>
</template>

<script>
import {
  DxScheduler,
  DxResource,
  DxEditing,
  DxView,
} from "devextreme-vue/scheduler";

// import timeZoneUtils from 'devextreme/time_zone_utils';

// import { events } from './data.js';

import DataCell from "./DataCell.vue";
import ResourceCell from "./ResourceCell.vue";

import screenWidth from "../../../mixins/screenWidth.js";

// import { getCalendarEventsFromAPI } from '../../assets/lanternsoft.calendar';

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    DxScheduler,
    DxResource,
    DataCell,
    ResourceCell,
    DxEditing,
    DxView,
  },
  mixins: [screenWidth],

  computed: {
    ...mapGetters([
      // 'getUser',
      // 'getTymeToolUser',
      "getGroups",
      "getEvents",
      "getMembers",
      "getTimezone",
      "getGroupEvents",
      "getPersonalEvents",
      "getCurrentGroup",
    ]),
    copiedArrayOfGroupEvents() {
      return this.$store.getters.getGroupEvents.slice(
        0,
        this.$store.getters.getGroupEvents.length
      );
    },
  },
  watch: {
  },
  beforeMount() {
    this.$store.dispatch("getEventsAndCalendarData");
  },

  data() {
    return {
      someEvents: null,
      groups: ["userID"],
      typeGroups: ["typeId"],
      views: ["month", "agenda", "week"],
      currentDate: new Date(),
      dataSource: [],
      typeData: [
        {
          text: "Available",
          id: 1,
          color: "#fff",
        },
        {
          text: "Unavailable",
          id: 2,
          color: "#fff",
        },
        {
          text: "Occupied",
          id: 3,
          color: "#fff",
        },
      ],
    };
  },
  // computed: {
  //   ...mapState(['groupusers']),
  //   updateDevExCal() {
  //     return this.groupusers
  //   }
  // },

  methods: {
    onAppointmentAdding(e) {
      console.log(e);
    },
    addHours(originalDt, hours) {
      return new Date(originalDt.setHours(originalDt.getHours() + hours));
    },
    onAppointmentAdded(e) {
      console.log(e);
      console.log(e.appointmentData);
      let { startDate, endDate, description, text, allDay } = e.appointmentData;
      console.log(startDate, endDate, description, text, allDay);

      if (allDay) {
        startDate = this.addHours(startDate, 9);
        endDate = this.addHours(endDate, 17);
      }

      let timezone = new Date().getTimezoneOffset();
      console.log(this.getGroups);
      console.log(this.getCurrentGroup);

      const sendEmailto =
        this.getGroups[this.getCurrentGroup.text]?.groupMembers;

      console.log(sendEmailto);

      let users;

      if (sendEmailto) {
        users = sendEmailto.map((user) => user.user);
      } else {
        users = [];
      }

      this.$store.dispatch("addEventToCalendar", [
        true,
        [this.getCurrentGroup.calUID],
        users,
        startDate,
        endDate,
        timezone,
        text,
        description,
        null,
      ]);
    },
    onAppointmentUpdated(e) {
      const { startDate, endDate, description, text, eventUID } =
        e.appointmentData;
      console.log(startDate, endDate, description, text, eventUID);
      let timezone = 1;
      this.onAppointmentDeleted(e);
      this.$store.dispatch("addEventToCalendar", [
        false,
        [this.getCurrentGroup.calUID],
        [],
        startDate,
        endDate,
        timezone,
        text,
        description,
        null,
        eventUID,
      ]);
    },
    onAppointmentDeleted(e) {
      // e.preventDefault();
      console.log("trying to delete");

      const { calUID, eventUID } = e.appointmentData;

      const calendarAndEventUIDs = {
        calUID,
        eventUID,
      };

      // const userDecision = confirm('are you sure');
      const userDecision = true;
      if (userDecision)
        this.$store.dispatch("RemoveEventFromCalendar", calendarAndEventUIDs);
      console.log(e);
    },

    // CODE COPIED FORM DEVEX DOCUMENTATION
    // customizeDateNavigatorText(e) {
    //     const formatOptions = {
    //         year: 'numeric',
    //         month: 'numeric',
    //         day: 'numeric'
    //     };
    //     const formattedStartDate = e.startDate.toLocaleString('en', formatOptions);
    //     const formattedEndDate = e.endDate.toLocaleString('en', formatOptions);
    //     if(this.currentView === 'day' | 'timelineDay')
    //         return formattedStartDate;
    //     if(this.currentView === 'month' )
    //         return e.startDate.toLocaleString('en', { year: 'numeric', month: 'numeric' });
    //     return formattedStartDate + ' - ' + formattedEndDate;
    // }
  },
};
</script>

<style lang="scss">
// THESE STYLES OVERRIDE THE CONFLICTING STYLES FROM './themes/generated/theme.base.css' THAT ARE USED
// TO STYLE THE AUTH COMPONENTS BUT BREAK THE CALENDAR.
.dx-scheduler-work-space {
  margin-top: 0 !important;
}
.dx-scheduler-work-space.dx-scheduler-work-space-month
  .dx-scheduler-date-table-cell {
  height: 50px !important;
}

// $border: 1px solid grey;

// .dx-tabs-wrapper {
//   background-color: rgb(110, 106, 106);
// }

// .dx-scheduler-appointment {
//   color: #000;
//   background-color: #fff;

// }

// .dx-scheduler-header-panel {
//   background-color: rgb(73, 70, 70);
//   margin: 0;
//   border-top: 1px solid grey;
// }

// .dx-scheduler-header-panel-cell {
//   background-color: #2a2a2a;
//   font-size: 110%;
//   // font-weight: bold;
//   color: #2ee7ff;
// }

// .dx-tooltip-appointment-item-content {
//   color: #fff;
// }

// .calendar__heading {
//   font-size: 160%;
//   color: #fff;
//   padding: .6rem;
// }

// .calendar__container-new-arch {
//   display: flex;
//     padding: 1rem;

//   @media only screen and (max-width: 40rem) {
//     display: inline-block;
//   }
// }

// .dx-state-hover {
//   background-color: rgb(110, 106, 106);
// }

.calendar__container > div {
  // padding: 1px;
  // border: 1px solid rgb(204, 204, 204);
  // box-shadow: 0 0 .3rem .1rem rgb(238, 238, 238);
  // margin:  0 1rem 0rem 1rem;
}

// .dx-scheduler-header {
//   color: #ffffff;
//   background-color: #2a2a2a;
//   border-top: $border;
//   border-left: $border;
//   border-right: $border;
//   border-bottom: 0;
// }

// .dx-scheduler-view-switcher {
//   display: none;
// }

// .dx-scheduler-navigator, .dx-widget {
//   background-color: #2a2a2a;
//   color: #ffffff;
//   font-size: 100%;
// }

// .dx-scheduler-navigator-previous {
//   color: #fff;
// }

// .dx-button-mode-contained {
//   color: #fff;
// }

// .dx-button-content

// .dx-icon {
//   color: #fff;
// }

// .dx-button-has-icon {
//   color: #fff;
// }

// .dx-scheduler-group-header, .dx-scheduler-date-table-cell {
//     position: relative;
//     color: #fff;
//     background-color: #2a2a2a;
// }
// .dx-scheduler-group-header, .dx-scheduler-date-table-cell:hover {
//     background-color: #555454;
// }

.dx-scheduler-date-table-other-month.dx-scheduler-date-table-cell {
  // opacity: .1;
  color: rgb(41, 41, 41);
  background-color: #3636364f;
}

// .dx-scheduler-date-table-cell .dx-template-wrapper {
//   color: #fff;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     padding-right: 6px;
// }
</style>
