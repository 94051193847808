<template>
  <div style="display: flex">
    <div class="select-box-holder">
        <div
          style="display: flex; justify-content: space-between"
          v-if="selectedValue"
        >
          <div class="title-overlay">
            <DisplayEmoji :text="selectedValue.groupName" :emoji="selectedValue.emoji"  />
          </div>
        </div>

      <DxSelectBox
        :value="selectedValue"
        height="46"
        :dataSource="dataSource"
        :grouped="false"
        @value-changed="emitSelectedValue"
        :disabled="disabled"
        placeholder=""
        >
        <template #item="{ data }">
          <DisplayEmoji :text="data.groupName" :emoji="data.emoji" :size="30" />
        </template>
      </DxSelectBox>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import DxSelectBox from "devextreme-vue/select-box";
import DisplayEmoji from "./DisplayEmoji.vue";

const props = defineProps({
  dataSource: Array,
  disabled: Boolean,
  selectedValue: String,
});

const emit = defineEmits(["selectedItem"]);

// let formattedDataSource = ref([]);

function showExp(e) {
  return e.desc;
}

function emitSelectedValue(e) {
  console.log('from sbox: '+e);
  emit("selectedItem", e.value);
}

// onMounted(() => {
//   formattedDataSource.value = props.dataSource.map((el) => ({
//     type: el.tag,
//     desc: el.tag,
//   }));
// });

// emitSelectedValue({ value: { type: props.selectedValue } });
</script>

<style lang="scss" scoped>
.select-box-holder {
  position: relative;
  // background-color: #ffffff !important;
}

.select-box-holder .dx-texteditor.dx-editor-filled {
  background-color: #ffffff !important;
}

.select-tag {
  position: absolute;
  top: 7px;
  left: 8px;
  font-size: 1.3rem;
}

.selected-tag {
  position: absolute;
  top: 0.2rem;
}

.title-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 98%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  border-radius: 5px;
  padding: 0.5rem;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
</style>
