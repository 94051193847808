<template>
    <div>
        <div class="create-group-button-container">
            <button class="create-group-button" @click="showGroupCreator = true">Create New Group</button>
        </div>
        <GroupsViewer/>
        <div v-if="showGroupCreator" class="modal" @click="closeModal($event)">
            <div class="modal-content">
                <span class="close-button" @click="showGroupCreator = false">&times;</span>
                <GroupCreator/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import GroupCreator from '../../../core/components/Groups/GroupCreator.vue'
import GroupsViewer from '../../../core/components/Groups/GroupsViewer.vue'

const showGroupCreator = ref(false);

const closeModal = (event) => {
    if (event.target.classList.contains('modal')) {
        showGroupCreator.value = false;
    }
}
</script>



<style scoped>
.modal {
    position: fixed;

    z-index:11;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    position: relative;
    /* background-color: #fefefe; */
    margin: 15% auto;
    /* padding: 20px; */
    width:fit-content;


    /* width: 80%; */
}

.close-button {
    position: absolute;
    top: -5px;
    right: 5px;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-button:hover,
.close-button:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.create-group-button {
    background-color: #05D0EB;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.create-group-button-container {
    margin: 20px 0 0 60px; 
}

.create-group-button:hover {
    background-color: #039cb7;
}
</style>