<template>
  <!-- <div class="emailcardcontainer" @click.self="closeEmailcard" v-if="showCard">
    <div class="emailcard" :style="`margin-top:${scrolledDown}px;`">
      <div class="close-btn" @click="closeEmailcard">x</div> -->
      <div class="emailcardgrid">
        <div class="emailcard__box subject">
          <h3 style="padding-bottom: 0.6rem">
            {{ Subject }}
            <!-- <EmailComponent
              :data="Subject"
              @select="selectComponent"
              @deselect="deselectComponent"
            /> -->
          </h3>
        </div>
        <div class="emailcard__box source">
          <div class="flex emailcard__header">
            <i class="icon-dx dx-icon-repeat"></i>
            <div class="">Source Address:</div>
          </div>
          <EmailComponent
            :data="sourceAddress"
            @select="selectComponent"
            @deselect="deselectComponent"
          />
        </div>
        <div class="emailcard__box time">
          <div class="flex emailcard__header">
            <i class="icon-dx dx-icon-clock"></i>
            <div class="">Time Received:</div>
          </div>

          <EmailComponent
            :data="timeReceived"
            @select="selectComponent"
            @deselect="deselectComponent"
          />
        </div>
        <div v-if="attachments?.length > 0" class="emailcard__box attachments">
          <div class="flex emailcard__header">
            <i class="icon-dx dx-icon-plus"></i>
            <div class="">Attachments:</div>
          </div>
          <EmailComponent
            v-for="(attachment, i) in attachments"
            :key="i"
            :data="attachment.Name"
            @select="selectComponent"
            @deselect="deselectComponent"
          />
        </div>

        <div class="emailcard__box emailbody">
          <div class="flex emailcard__header">
            <i class="icon-dx dx-icon-message"></i>
            <div class="">Body:</div>
          </div>
          <EmailComponent :data="bodySize" />
        </div>
        <div v-if="tags" class="emailcard__box tags">
          <div class="flex emailcard__header">
            <i class="icon-dx dx-icon-pin"></i>
            <div class="">Tags:</div>
          </div>
          <div>
            <DisplayTagsVue :tags="tags" :size="30" />
          </div>
        </div>
      </div>
    <!-- </div>
  </div> -->
</template>

<script setup>
import { doingTasks, plannedTasks } from "./data.js";

import EmailComponent from "./EmailComponent.vue";
import { computed, ref } from "vue";
import EmailTagger from "./EmailTagger.vue";
import DisplayTagsVue from "./DisplayTags.vue";

import ModelTagsCellRendererVue from "./ModelTagsCellRenderer.vue";

const props = defineProps({
  cardData: Object,
  showCard: Boolean,
  // scrolledDown: Number,
});

const selectedComponents = ref([]);
const emit = defineEmits(["close"]);

console.log(props);

const timeReceived = computed(() => props.cardData[0]?.SentTime);
const Subject = computed(() => props.cardData[0]?.Subject);
const sourceAddress = computed(() => props.cardData[0]?.From);
const attachments = computed(() => props.cardData[0]?.Attachments);
const bodySize = computed(() => props.cardData[0]?.Body?.toString());
const sentFrom = computed(() => props.cardData[0]?.sentFrom?.toString());
const tags = computed(() => props.cardData[0]?.ModelTags?.TagsList);

// {type: 'User', desc: 'monim@VentureStar.com (OWNER1)'}

function selectComponent(val) {
  selectedComponents.value.push(val);
}

function deselectComponent(val) {
  const index = selectedComponents.value.findIndex((index) => index === val);
  selectedComponents.value.splice(index, 1);
}

function closeEmailcard(e) {
  if (e) emit("close");
}
</script>

<style lang="scss">
// .emailcardcontainer {
//   position: absolute;
//   width: 120%;
//   height: 100%;
//   top: 30;
//   left: -10%;
//   background-color: rgba(82, 82, 82, 0.712);
// }

.outline {
  border: 1px dotted grey;
}

.subject {
  grid-area: subject;
}

.emailbody {
  grid-area: emailbody;
}

.attachments {
  grid-area: attachments;
}

.tags {
  grid-area: tags;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.emailcard {
  // position: relative;
  // background-color: #fff;
  // text-align: left;
  // border-radius: 5px;
  // border: 1px solid grey;
  // // top: 40%;
  // left: 50%;
  // width: 70vw;
  // transform: translate(-50%, -50%);
  // font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  // text-align: left;
  // padding: 1rem;
  // box-shadow: 5px 5px 15px 2px rgba(91, 91, 91, 0.521);
  // overflow: auto;

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    padding: 1rem;
  }

  &__box {
    border-bottom: 1px solid rgb(201, 201, 201);
    // border: 1px dotted red;
    padding: 1rem;
    max-width: 30vw;

    & .icon-dx {
      font-size: 24px;
      color: #1271f7;
    }
  }
}

.flexed {
  display: flex;
  flex-wrap: wrap;
}

.emailcardgrid {
  width: 100%;
  display: grid;
  grid-template:
    "subject subject subject"
    "source time time"
    "tags emailbody emailbody"
    "attachments emailbody emailbody";
}

.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>
