<template>

  <div
    :class="{'user-busy' : markUserBusy(ourData)}"
  >
    <div :class="markLocation(cellData)">
      {{ cellData.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cellData: {
      type: Object,
      default: () => {}
    },
    ourData: Object,
  },
  methods: {
    markWeekEnd(cellData) {
      function isWeekEnd(date) {
        const day = date.getDay();
        return day === 0 || day === 6;
      }
      const classObject = {};
      classObject[`groupuser-${cellData.groups.userID}`] = true;
      classObject[`groupuser-weekend-${cellData.groups.userID}`] = isWeekEnd(cellData.startDate);
      return classObject;
    },

    markUserBusy(ourData) {
      // console.log(cellData);
      return ourData.text === 'plssss'
    },

    markLocation(cellData) {
      // console.log(this.ourData);
      const classObject = {
        'day-cell': true
      };

      classObject[this.getCurrentLocation(cellData.startDate)] = true;
      return classObject;
    },

    getCurrentLocation(date) {
      // var day = date.getDay();
      // var result = day === 0 || day === 6 ? 1 :0;
      // var currentLocation = `background-${result}`;
      // console.log(currentLocation);
      // return currentLocation;
    }
  }
};
</script>

<style lang="scss">
.day-cell {
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.groupuser-1 {
  background-color: rgba(52, 132, 240, 0.1);
}

.groupuser-2 {
  background-color: rgba(107, 164, 240, 0.2);
}

.groupuser-weekend-1 {
  background-color: rgba(52, 132, 240, 0.2);
}

.groupuser-weekend-2 {
  background-color: rgba(196, 219, 250, 0.4);
}

.background-0 {
    background-image: url("../../../assets/tymetool/office.png");
}

.background-1 {
    background-image: url("../../../assets/tymetool/remote.png");
}

.dx-theme-material .name h2 {
    font-size: 28px;
}
</style>
