<template>
  <div>
    <div class="bgi"></div>
    <!-- <div class="landing-page" style="width: 100vw">
    </div> -->
    <div class="login-form-container">
      <div class="mobile-header-bar" v-if="isMobile">
        <img src="/favicon.ico" width="50" />
        <button @click="showAuthForm" class="sign-up-button">Sign Up</button>
      </div>

      <div>
        <h1 class="title headline">
          VentureStar
          <br v-if="!isMobile" />
          <img  v-if="!isMobile" src="../assets/logoemail.png" style="height: 50px;" /> <span style="color:#fff1c9;">Email</span> 
        </h1>
        <div class="first-tagline">Intelligent Emailing</div>
        <div class="tagline">For groups and individuals</div>
        <!-- <img src="../assets/email.jpeg" width="300" alt="" /> -->
      </div>

      <div v-if="!isMobile || showForm" class="waitlist-form">
        <div style="font-size: 40px; padding-left: 30px; padding-right: 30px; text-transform: bold">
          Priority List
        </div>
        As we add more resources we can invite you for a <span style="color: #ec2aaf; font-weight: bold">FREE EARLY BIRD ACCOUNT</span> 
        <br/>
        <img class="image" src="../assets/machine.jpg" style="width: 250px;" />
        <br/>
        
          <DxTextBox style="background-color: #ffffff;"
              placeholder="Enter email address..."
              :input-attr="{ 'aria-label': 'Email Address' }"
              v-model="emailEntered"
            />

                <dxButton :visible="true" text="Add me to the list!" @click="waitList">
              </dxButton>
   
      </div>

      <div v-if="!isMobile || showForm" class="auth-form">
        <div style="font-size: 40px; padding-left: 30px; text-transform: bold">
          {{ title }}
        </div>
        <slot />
      </div>

    </div>

    <div class="section">
      <div class="bgi2 bgi-generic">
        <div class="infoBoxBright" style="margin-top: 150px; padding: 50px">
          <div class="tagline">
            Fight against<br />
            <span style="color: #ec2aaf">information overload</span><br />
            with <strong>smart</strong> email knowledge bases and
            <strong>shared</strong> data views <br />
          </div>
          <img class="image" src="../assets/fight.jpg" />
        </div>
      </div>
    </div>
    <div class="bgi3 bgi-generic" ref="bgi3" id="demo">
      <video
        class="demo-video"
        src="https://venturestar-files.s3.eu-west-2.amazonaws.com/VentureStarEmailPromo.mp4"
        id="horses"
        type="video/mp4"
        controls
      >
        -webkit-transform:scale(1.5);-o-transform:scale(1.5);-ms-transform:scale(1.5);transform:scale(4)
        translate(0px,-400px);"> Your browser does not support the video tag.
      </video>
    </div>
    <div class="bgi4 bgi-generic">
      <div class="infoBoxBright" style="margin-top: 150px; padding: 50px">
        <div class="tagline">
          Have an early bird access code? <br />
          Sign up for a
          <span style="color: #ec2aaf; font-weight: bold">FREE</span> account
          <br />
        </div>
        <img class="image" src="../assets/screen.png" />
        <div class="dx-field-value">
        <dxButton :visible="true" text="Sign me up!" @click="signUp">
        </dxButton>
      </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { watch, ref, onMounted, computed } from "vue";
import { DxButton } from "devextreme-vue/button";
import DxTextBox from 'devextreme-vue/text-box';
import {subscribeToMailingList} from "../../../venturestar-network-connect/src/assets/lanternsoft.connect.js";

import { useScreenWidth } from "../../../core/mixins/screenWidthScriptSetup";
const { screenWidth } = useScreenWidth();

const isMobile = computed(() => screenWidth.value < 600);

function showAuthForm() {
  showForm.value = !showForm.value;
}

function waitList(){
  let emailAddress = emailEntered.value;
  //alert(emailAddress);
  //alert(JSON.stringify(emailEntered, null, 4));
  subscribeToMailingList(emailAddress);
  
}

const showForm = ref(false);

const route = useRoute();

const router = useRouter();

console.log(route.query.show);

const title = ref(route.meta.title);
const description = ref("");
const emailEntered = ref("");

const bgi3 = ref(null);

function scrollToDiv() {
  if (bgi3.value) {
    bgi3.value.scrollIntoView({ behavior: "smooth" });
  }
}

onMounted(() => {
  if (route.query.show === "video") {
    scrollToDiv();
  }
});

watch(
  () => route.path,
  () => {
    title.value = route.meta.title;
    description.value = route.meta.description;
  }
);

async function signUp() {
  router.push("/create-account");
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
</script>

<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";

.section {
  // height: 100vh;
}

.image {
  margin: 0 auto;
  width: 400px;
  margin-top: 30px;
  @media (max-width: 600px) {
    width: 200px;
    margin-top: 10px;
  }

}

.mobile-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem;
}

.demo-video {
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
}

.sign-up-button {
  background-color: #4caf50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Mouse pointer on hover */
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Transition effects */
}

.sign-up-button:hover {
  background-color: #45a049; /* Green background on hover */
}

.bgi {
  background-image: url("../assets/emailbgi.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 101vh;
  transform: scaleX(1);
  position: absolute;
  display: block;
  @media (max-width: 600px) {
    height: 90vh;
  }
}

.bgi-generic {
  background-repeat: no-repeat;
  background-size: cover;
  //opacity: 0.5;
  width: 100vw;
  height: 100vh;
  transform: scaleX(1);
  border-radius: 100px;
  margin: 0 auto; //magic centering
  text-align: left;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    border-radius: 10px !important;
    height: 70vh;
    margin-top: 40vh; 
  }
}

.bgi2 {
  padding: 1rem;
  background-color: #899fff7b;
 
}

.bgi3 {
  background-image: url("../assets/filing.jpg");
  border-radius: 0px;


  @media screen and (max-width: 600px) {
    height: auto;
  }
}

.bgi4 {
  margin-top: 50px;
  background-color: #e0e9ffcf;

  border-radius: 200px;


  @media (max-width: 600px) {
    border-radius: 10px;
    margin-top: 5px;
    height: 70vh;
  }
}

.bgi5 {
  margin-top: 50px;
  background-color: #8fcdffcf;
  width: 100vw;
  height: 100vh;
  transform: scaleX(1);
  border-radius: 200px;
  margin: 0 auto; //magic centering
  text-align: left;
  display: flex;
  justify-content: center;
}

.infoBoxBright {
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  padding: 10px; /* Add some space around the content */

  width: 100vmin;
  height: 70vmin;
  @media (max-width: 600px) {
    height: 90vmin;
  }

  display: grid;
  place-content: center;
  color: white;
  font-size: large;
  text-shadow: 0 1px 0 #000;

  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    #213,
    #112 5%,
    #112 60%,
    #213 95%
  );

  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #08f,
    rgb(255, 234, 0) 99%,
    transparent
  );

  background: 
    // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box,
    // border-box extends this background to the border space
    var(--gradient-border) border-box,
    // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  // &:hover {
  //   animation-play-state: paused;
  // }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.title {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0 0 5rem 0;
  font-size: 4rem !important;
}

.landing-page {
  width: 100vw;
  height: 100vh;
  // opacity: 0%;
  position: absolute;
  background-image: linear-gradient(
    to bottom right,
    $base-accent,
    $base-accent,
    rgb(147, 251, 255),
    // rgb(147, 251, 255),
  );
}

.headline {
  margin: 0 0 1rem 0;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;;
  font-size: 4rem;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
}

.first-tagline {
  text-transform: uppercase;
  color: #ffffff;
  font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;;
  margin: 3rem 0 1rem 0;
  font-size: 200%;
  font-weight: bold;

  @media screen and (max-width: 600px) {
    margin: 1rem 0 0 0;
  }
}

.tagline {
  margin: 1rem 0 0 0;
  text-transform: uppercase;
  color: white;
  font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;;
  font-size: 200%;
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
}

.login-form-container {
  width: 100vw;
  margin: auto;
  padding: 2rem 8rem 0 3rem;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  gap: 5rem;
  transform: scaleX(1);
  margin-bottom: 10vh;

  @media (max-width: 600px) {
    padding: 0.5rem;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 10vh;
  }
}

.auth-form {
  margin: 1rem 0 0 0;
  opacity: 100%;
  border-bottom: 1px solid #ccc;
  width: 35rem;
  padding: 1rem;
  background-color: #fff;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.waitlist-form {
  margin: 1rem 0 1rem 0;
  opacity: 100%;
  border-bottom: 1px solid #ccc;
  width: 450px;
  height: 100%;
  padding: 1rem;
  background-color: #fff;
  text-align: center;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
</style>
