
export default {
    // getGroups(state) {
    //   return state.groupList;
    // },
    getPersonalEvents(state) {
      return state.events;
    },
    getEvents(state) {
      return [...state.events, ...state.groupEvents];
    },
    getTymeToolUser(state) {
      return state.tymeToolUser;
    },
    getGroupEvents(state) {
      return state.groupEvents;
    },
    getMembers(state) {
      return [...state.personalCalendar.members, state.currentGroup];
    },

    getAllCalsAndEvents(state) {
      return state.allCalsAndEvents
    },

    getPersonalCalendar(state) {
      return state.personalCalendar;
    },
    
    getAllCurrentGroupMembers(state) {
      return state.currentGroupMembers;
    },
    getCurrentGroup(state) {
      return state.currentGroup;
    },
    getTimezone(state) {
      return state.timezone;
    },






    getUniqueKeys(state) {
      return state.uniqueKeys;
    },
    getEventsFromAPI(state) {
      return state.eventsFromAPI
    },






    getLinkedEvents(state) {
      return state.linkedEvents;
    },


    isModalVisible(state) {
      return state.loginModalVisible
    }

  }