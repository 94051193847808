import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp } from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";
import store from "./store"

import App from "./App.vue";
import appInfo from "./app-info";


const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch('/runtimeConfig.json')
  return runtimeConfig.json()
}

getRuntimeConfig().then(function (json) {
  const apiEndpoint = json.VUE_APP_APIURL;
  const modelHub = json.modelHub;
  const apiKey = json.API_KEY;

  store.commit('calendarAPI', json.calendarAPI)

  store.commit('modelHub', modelHub)
  store.commit('VUE_APP_APIURL', apiEndpoint)
  store.commit('apiKey', apiKey)
  // store.commit('testData', json.testData);
  if (json.testData) {
    store.dispatch('loadTestData');
  }

  router.options.routes.forEach((route: { name: string; props: { endpoints: any[]; }; }) => {
    if (route.name === 'create-account') {
      route.props = {
        endpoints: [apiEndpoint]
      }
    }
  })

  createAppAfterAPIInjection()
})

function createAppAfterAPIInjection() {
  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.config.globalProperties.$appInfo = appInfo;
  app.mount('#app')
}


// themes.initialized(() => {
//     const app = createApp(App);
//     app.use(router);
//     app.use(store);
//     app.config.globalProperties.$appInfo = appInfo;
//     app.mount('#app');
// });
