/* eslint-disable */
// eslint-disable-next-line alert('foo');
// import router from '../../router';

export default {
  async createMultipleAccounts(context, payload) {
    console.log(payload);
    try {
      let formData = payload.formData;
      // let apiKey = context.getters.apiKey;
      let apiKey = "z7t3r4e9872mn65ty47dt3erstyw543i9";
      console.log(apiKey);
      console.log(formData.password, formData.email, formData.accessCode);
      // Send request

      var user = {
        Password: formData.password,
        ConfirmPassword: formData.password,
        UserName: formData.email,
        EmailAddress: formData.email,
      };

      let endpoint = payload.endpoint;

      let didItWork;

      // endpoints.forEach((endpoint) => {
      console.log(endpoint);
      fetch(`${endpoint}/Register`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: apiKey,
          AccessCode: formData.accessCode,
        },
        method: "POST",
        body: JSON.stringify(user),
      })
        .then(
          (response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error('Network response was not ok');
            }
          },
          (reject) =>
            alert("No data available - please try again soon - " + reject)
        )
        .then((json) => {
          if (json) {
            console.log(json);
            alert(
              "You have been registered. Please check " +
              formData.email +
              " for an email verification link to complete your registration."
            );
          } else {
          }
        })
        .catch((err) => {
          alert("error: " + err.message);
          console.log(err);
        });
      // });
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },
  async createAccount(context, formData) {
    try {
      // let apiKey = context.getters.apiKey;
      let apiKey = "z7t3r4e9872mn65ty47dt3erstyw543i9";
      console.log(apiKey);
      console.log(formData.password, formData.email, formData.accessCode);
      // Send request

      var user = {
        Password: formData.password,
        ConfirmPassword: formData.password,
        UserName: formData.email,
        EmailAddress: formData.email,
      };

      console.log(context.getters.VUE_APP_APIURL);

      fetch(`${context.getters.VUE_APP_APIURL}/Register`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: apiKey,
          AccessCode: formData.accessCode,
        },
        method: "POST",
        body: JSON.stringify(user),
      })
        .then(
          (response) => {
            if (response.ok) {
              return response.json();
            } else {
              alert(
                "Server returned " +
                response.status +
                ": " +
                response.statusText
              );
            }
          },
          (reject) =>
            alert("No data available - please try again soon - " + reject)
        )
        .then((json) => {
          if (json) {
            console.log(json);
            alert(
              "You have been registered. Please check " +
              formData.email +
              " for an email verification link to complete your registration."
            );
          }
        })
        .catch((err) => {
          alert("error: message =" + err.message + "  name  = " + err.name);
          console.log(err);
        });

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },

  async verifyUser(context, props) {
    const router = useRouter();

    let returnStatement = "hi";

    try {
      let apiKey = context.getters.apiKey;
      console.log(apiKey);
      console.log(props.email, props.token);

      await fetch(
        `${context.getters.VUE_APP_APIURL}/Verify?emailAddress=` + props.email,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ApiKey: apiKey,
            Authorization: "Bearer " + props.token,
          },
          method: "POST",
        }
      )
        .then(
          (response) => {
            if (response.ok) {
              console.log("response is ok");
              return response.json();
            } else {
              returnStatement = "good";

              console.log(
                "Server returned " +
                response.status +
                " : " +
                response.statusText
              );
            }
          },
          (reject) =>
            console.log("No data available - please try again soon - " + reject)
        )
        .then((json) => {
          console.log(json);
          if (json) {
            alert("Verified! Please log in to use Tyme Tool.");
            router.push("/login-form");
            returnStatement = {
              isOk: json,
              message: "Verification success",
            };
            return returnStatement;
          }
        })
        .catch((err) => {
          alert("Incorrect login" + err.message + "  name  = " + err.name);
          console.log(err);
          return {
            isOk: false,
            message: "Verification failed",
          };
        });
    } catch {
      return {
        isOk: false,
        message: "Verification failed",
      };
    }
    return returnStatement;
    // router.push("/login-form");
  },

  async logIn(context, formData) {
    console.log("in new arch");
    console.log(formData);

    // context.commit('setUserEmail', formData.email);
    let apiKey = context.getters.apiKey;

    console.log(context.getters.VUE_APP_APIURL);

    const response = await fetch(
      `${context.getters.VUE_APP_APIURL}/Login?username=` +
      formData.email +
      "&password=" +
      formData.password,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ApiKey: apiKey,
        },
        method: "POST",
      }
    );

    console.log(response);

    if (!response || response.status === 400 || response.status === 500) {
      console.log(
        "Server returned " + response.status + " : " + response.statusText
      );
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }

    const responseJSON = await response.json();

    console.log(responseJSON);

    // this._user = {
    //   email: formData.email,
    //   avatarUrl:
    //     "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png",
    //   bearerToken: responseJSON["access_token"],
    // };

    context.commit("setLoggedInUser", {
      email: formData.email,
      avatarUrl:
        "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png",
      bearerToken: responseJSON["access_token"],
    });
    context.commit("setAppInContext", responseJSON["application"]);

    localStorage.setItem(
      "loggedInUser",
      JSON.stringify(context.getters.getUser)
    );
    localStorage.setItem("appInContext", context.getters.getAppInContext);

    // NOTE: This action must return: { isOk: true, data: context.getters.getUser.email } in order to work with the login form.
    return context.dispatch("applicationSpecificStartup");
    //  return {
    //   isOk: true,
    //   message: "hiii",
    // };
  },

  // async getEventsAndCalendarData(context) {
  //   console.log("🚀 ~ file: actions.js:274 ~ getEventsAndCalendarData ~ context:", context)

  //   context.dispatch("getOwnersCalendars", context.getters.getUser.email);
  //   await context.dispatch("getUsersGroups", context.getters.getUser.email);
  //   // await context.dispatch("getLinkedCalendars", context.getters.getUser.email);
  //   await context.dispatch("getLinkedCalendars", '744755862');
  // },

  checkIfTokenHasExpired(context, response) {
    console.log("yep got here");
    if (response.status === 401) {
      // router.push("/login-form");
      context.commit('logUserOut');
      context.dispatch('navigateToLoginForm')
      context.commit("setNotifyMessage", {
        message:
          "Your session has expired. Please log in to start a new session.",
        wasContentSaved: "",
      });
      // context.commit('logUserOut');
      // return true;
    }
  },
}; // end of export default
