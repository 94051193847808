<template>
  <div class="emailcomponent"
       @click="emitComponentData"
       :class="{'emailcomponent__selected' : selected}"
    > 
      {{ data }}
  </div>
</template>  

<script setup lang="ts">
import { ref } from 'vue';
 
const props = defineProps({
  data: String
});
const emit = defineEmits(['select', 'deselect']);
 
let selected = ref(false);

function emitComponentData() {
  selected.value = !selected.value;
  if (selected.value) {
    emit('select', props.data);
  } else {
    emit('deselect', props.data);
  }
}

</script>

<style lang="scss">
.emailcomponent {
    cursor: pointer;
    border: 1px solid rgb(185, 185, 185);
    padding: 7px 10px;
    width: fit-content;
    border-radius: 4px;

    &__selected {
      // margin: 1px;
      border: 1px solid  rgb(0, 145, 255);
    }
}

</style>