<template>
  <div class="flex">
    <div v-for="(tag, i) in tagCounts" :key="i">
      <div style="padding-left:10px" class="flex ">
        <div>
          <img v-if="!largeIcon" width="30" :src="getIcon(tag.tag)" />
          <img v-if="largeIcon" width="40" :src="getIcon(tag.tag)" />
        </div>
        <h3 v-if="tag.count > 1">
          {{ tag.count }}
        </h3>
        <!-- <div v-if="tag.desc">
          {{ tag.desc }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import DisplayTagsVue from './DisplayTags.vue'

export default {
  props: {
    TagsList: Array,
    LargeIcon: Boolean
  },
  setup(props) {

    const tagCounts = []
    const largeIcon = props.LargeIcon;
    props.TagsList.forEach((tag) => {
      let index = tagCounts.findIndex((tagg) => tagg.tag === tag)

      if (index === -1) {
        tagCounts.push({ tag, count: 1 })
      } else {
        tagCounts[index].count++
      }
    })

    // const TagsList = props.params.data.ModelTags.TagsList

    function getIcon(modelType) {
      try {
        return require(`../../assets/icons/${modelType.toLowerCase()}.png`) //note backticks used for String interpolation
      } catch (error) {
        console.log(error)
        return require(`../../assets/icons/email.png`)
      }
    }
    return { getIcon, tagCounts, largeIcon }
  }
}
</script>

<style></style>
