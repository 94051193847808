<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { AgGridVue } from "ag-grid-vue3";
import ModelTagsCellRenderer from "./ModelTagsCellRenderer.vue";
import TimeDisplayVue from "./TimeDisplay.vue";

import { useStore } from "vuex";
const store = useStore();

import { watch, onMounted, defineEmits } from "vue";

const emit = defineEmits(["clickedRow"]);

const props = defineProps({
  dataSource: Array,
  columnDefs: Array,
  filter: {
    type: String,
    default: () => "",
  },
  textFilter: String,
});

const columnTemplate = { resizable: true };
let gridApi;

watch(props.dataSource, (first, second) => {
  const newObj = first[first.length - 1];
  addRow(newObj);
});

let filter = "";
let textFilter2 = "";

watch(
  () => props.filter,
  (newVal, oldVal) => {
    setTimeout(() => {
      externalFilterChanged(newVal);
    }, 100);
  }
);
watch(
  () => props.textFilter,
  (newVal, oldVal) => {
    setTimeout(() => {
      externalTextFilterChanged(newVal);
    }, 100);
  }
);

function addRow(row) {
  gridApi.applyTransaction({ add: [row] });
  gridApi.sizeColumnsToFit({});
}

function onGridReady(params) {
  gridApi = params.api;

  props.dataSource?.forEach((datum) => {
    addRow(datum);
  });
}

function emitClick(e) {
  let row = gridApi.getSelectedRows();
  emit("clickedRow", row);
}

let components = {
  ModelTagsCellRenderer: ModelTagsCellRenderer,
  TimeDisplay: TimeDisplayVue,
};

function isExternalFilterPresent() {
  return filter !== "" || textFilter2 !== "";
}

function externalFilterChanged(newValue) {
  // console.log("🚀 ~ externalFilterChanged ~ newValue:", newValue)
  filter = newValue;
  if (!gridApi) return;
  gridApi.onFilterChanged();
}
function externalTextFilterChanged(newValue) {
  textFilter2 = newValue.toLowerCase();
  gridApi.onFilterChanged();
}

function doesExternalFilterPass(node) {
  if (node.data) {
    if (filter === "") {
      filter = props.filter;
    }
    if (filter === "") {
      return node.data.ModelTags.TagsList.some((attachment) =>
        attachment.desc.toLowerCase().includes(props.textFilter || textFilter2)
      );
    }

    if (props.textFilter === "" || textFilter2 === "") {
      return node.data.ModelTags.TagsList.some((el) => el.type === filter);
    }

    let bool = false;

    node.data.ModelTags.TagsList.forEach((tag) => {
      if (
        tag.desc.toLowerCase().includes(props.textFilter || textFilter2) &&
        tag.type === filter
      ) {
        bool = true;
      }
    });

    return bool;
  }
}

// this component is not tracking changes to filter properly so this is hack.
onMounted(() => {
  console.log("🚀 ~ onMounted ~ onMounted:", onMounted);
  setTimeout(() => {
    externalFilterChanged(store.getters.tagsFilter);
  });
});
</script>

<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: 700px"
      class="ag-theme-alpine"
      :defaultColDef="columnTemplate"
      rowSelection="single"
      :columnDefs="columnDefs"
      @grid-ready="onGridReady"
      :components="components"
      @click="emitClick"
      :doesExternalFilterPass="doesExternalFilterPass"
      :isExternalFilterPresent="isExternalFilterPresent"
    >
    </ag-grid-vue>
  </div>
</template>
