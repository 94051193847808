<template>
  <div>
    <!-- <div v-for="group in Groups" :key="group" @click="selectGroup">
      {{ group }}
    </div> -->
    <SelectBoxWithIcon
      :dataSource="[{groupName: 'Group 1', emoji: '📆'}, {groupName: 'Group 2', emoji: '😄'}, {groupName: 'Personal Space', emoji: '🤫'}]"
      :disabled="false"
      @selectedItem="selectGroup"
      :selectedValue="selectedItem"
      />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import SelectBoxWithIcon from "../../../core/components/UserSelectable/SelectBoxWithEmoji.vue";
// const props = defineProps({
//   Groups: Array,
// });
const store = useStore();

const selectedItem = ref({});

const events = {
  "Group 1": [
    { "text": "Group meeting", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-14T00:00:00.000Z", "endDate": "2024-03-14T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
    { "text": "Meet client", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-04T00:00:00.000Z", "endDate": "2024-03-04T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
    { "text": "followup client", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-06T00:00:00.000Z", "endDate": "2024-03-06T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
    { "text": "Discussing merger of groups", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-26T00:00:00.000Z", "endDate": "2024-03-26T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
  ],
  "Group 2": [
    { "text": "Group meeting", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-15T00:00:00.000Z", "endDate": "2024-03-15T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
    { "text": "Discussing merger of groups", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-26T00:00:00.000Z", "endDate": "2024-03-26T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
    { "text": "Friday pizza party", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-29T00:00:00.000Z", "endDate": "2024-03-29T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" },
   ],
  "Personal Space": [
    { "text": "Dentist appointment", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-05T00:00:00.000Z", "endDate": "2024-03-05T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" }, 
    { "text": "Karate competition", "userID": "zacky.frizell@gmail.com", "startDate": "2024-03-28T00:00:00.000Z", "endDate": "2024-03-28T10:00:00.000Z", "attendees": [], "calUID": "20230422_1048_17488efef226-7131-4f3a-b07b-9274c7", "eventUID": "20230814T140439Z65dcc5f7-3e67-45", "typeId": 0, "emoji": "📆" }, 
  ],
}

const selectGroup = (e) => {
  console.log("🚀 ~ selectGroup ~ e:", e)
  console.log("group selected");
  selectedItem.value = e;
  const selectedGroupsEvents = events[selectedItem.value.groupName];
  console.log("🚀 ~ selectGroup ~ selectedItem.value.groupName:", selectedItem.value.groupName)
  console.log("🚀 ~ selectGroup ~ selectedGroupsEvents:", selectedGroupsEvents)
  // store.dispatch('setGroupInContext', e);
  store.commit('newSetGroupEvents', selectedGroupsEvents);
};

onMounted(() => {
  selectGroup({groupName: 'Group 1', emoji: '📆'});
})

</script>

<style></style>
