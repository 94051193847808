<template>
  <div @click="addMemberToList" class="groups__users"
    :class="{'selected-user' : checkboxChecked}">
    <label class="groups__users-user">
       {{ member.member }}
    </label>
  </div>
</template>

<script>
export default {
    props: {
        member: Object,
        index: Number,
    },
    data() {
        return {
            checkboxChecked: this.member.checked, 
        }
    },
    methods: {
        addMemberToList() {
            this.checkboxChecked = !this.checkboxChecked;
            if (this.checkboxChecked) {
                 this.$emit('selected', this.member);
            } else if (!this.checkboxChecked) {
                this.$emit('deselect', this.member);
            }
           
        }
    }

}
</script>

<style lang="scss">


.groups__users {
    display: inline-block;
    margin: 2px;
    border: 1px solid grey;
    border-radius: .4rem;
    padding: 4px 6px;
    // background-color: #fff;
    color: #2a2a2a;
    // font-size: 80%;

    &-user {
    cursor: pointer;
    }
}

.selected-user {
    background-color: #05d0eb2a;
}



</style>