<template>
  <div class="email-sync-container">
    <div>
      <div @click="copyEmail" class="send-email-container">
        <div @click="copyEmail" class="email">
          {{ intelligentEmailAddress }}
        </div>
        <div @click="copyEmail" class="icon">
          <span :class="{ visable: visable }" class="message">Copied!</span>
          <i class="fa-solid fa-copy"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
let intelligentEmailAddress = computed(
  () => store.getters.intelligentEmailAddress
);

let visable = ref(false);

function copyEmail() {
  console.log(navigator);

  navigator.clipboard.writeText(intelligentEmailAddress.value);
  visable.value = true;

  setTimeout(() => {
    visable.value = false;
  }, 2000);
}
</script>

<style scoped lang="scss">
.email-sync-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.message {
  position: absolute;
  background-color: rgb(0, 0, 0);
  color: #fff;
  padding: 4px 8px;
  font-size: 80%;
  // width: 70px;
  border-radius: 7px;
  left: -6px;
  top: -30px;
  opacity: 0;
}

.visable {
  opacity: 1;
}

.send-email-container {
  width: 300px;
  height: 30px;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px;
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: space-between;
  // padding: 0px 15px;
}

.icon {
  position: relative;
  border-left: 1px solid rgb(173, 173, 173);
  padding: 5px 15px;

  &:hover {
    cursor: pointer;
  }
}

.email {
  padding: 5px 15px;
}
</style>
