// import { ref } from 'vue';
import store from '../store/index';
import { createConnection } from "../../../core/signalr/clients/EmailDataHub";
import { showToast } from "../../../core/utils/showToast";
export function useMyMixin() {

    const createHubProxy = (proxy) => {

        console.log("🚀 ~ createHubProxy ~ proxy:", proxy);
        const { myHub, connection } = createConnection(
            store.getters.modelHub,
            proxy,
            store.getters.temporaryToken
        );

        return { myHub, connection };
    };

    async function getJWT() {
        console.log("🚀 ~ getJWT ~ getJWT:", getJWT)


        try {
            await store.dispatch("emailSync");
            await store.dispatch("getIntelligentEmailAddress");

            // connectToHubs();
        } catch (error) {
            console.error("Error in fetchData:", error);
        }
    }

    function connect(hubName, callback) {
        const { myHub, connection } = createHubProxy(hubName);
        myHub.on("update", (p) => {
            callback(p);
        });
        connection.disconnected(function () {
            console.log("Connection lost for" + hubName);
            setTimeout(() => {
                console.log('connected quick');
                connect(hubName, callback);
            }, 200);
        })

    }

    const connectToHubs = () => {


        // createHubProxy("GroupHub");
        connect("GroupHub", (p) => {
            console.log("🚀 ~ GroupHub ~ p:", p);
        });
        // createHubProxy("JobHub");
        // createHubProxy("ProjectHub");
        // createHubProxy("WebKnowledgeBaseHub");
        connect("WebKnowledgeBaseHub", (p) => {
            console.log("🚀 ~ WebKnowledgeBaseHub ~ p:", p);
        });
        // const EmailDataHub = createHubProxy("EmailDataHub");
        connect("EmailDataHub", (p) => {
            console.log("🚀 ~ EmailDataHub.on ~ p:", p)
            store.commit("addEmail", p);
            store.dispatch("extractTagsFromEmails", [p]);
            showToast("Email", "✉️");
        });
        // EmailDataHub.on("update", (p) => {
        //     store.commit("addEmail", p);
        //     store.commit("extractTagsFromEmails", [p]);
        //     showToast("Email", "✉️");

        // });
        // const CalendarEventHub = createHubProxy("CalendarEventHub");
        // CalendarEventHub.on("update", (p) => {
        //     console.log("🚀 ~ CalendarEventHub.on ~ p:", p)
        //     store.commit("addCalendarEvent", p);
        //     store.commit('newSetGroupEvents', p);
        //     showToast("Calendar", "📅");

        // });
        connect("CalendarEventHub", (p) => {
            console.log("🚀 ~ CalendarEventHub.on ~ p:", p)
            store.commit("addCalendarEvent", p);
            store.commit('newSetGroupEvents', p);
            showToast("Calendar", "📅");
        });
        // const DocumentHub = createHubProxy("DocumentHub");
        // DocumentHub.on("update", (p) => {
        //     console.log('DocumentHub');
        //     console.log(p);
        //     store.commit("addDocument", p);
        //     if (p.DocumentFormat === 4 || p.DocumentFormat === 5 || p.DocumentFormat === 6) {
        //         showToast("Data", "📊");
        //     }
        //     if (p.DocumentFormat === 7) {
        //         showToast("PDF", "📄");
        //     }
        //     if (p.DocumentFormat >= 15 && p.DocumentFormat <= 19) {
        //         showToast("Image", "📸");
        //     }
        // });
        connect("DocumentHub", (p) => {
            console.log('DocumentHub');
            console.log(p);
            store.commit("addDocument", p);
            if (p.DocumentFormat === 4 || p.DocumentFormat === 5 || p.DocumentFormat === 6) {
                showToast("Data", "📊");
            }
            if (p.DocumentFormat === 7) {
                showToast("PDF", "📄");
            }
            if (p.DocumentFormat >= 15 && p.DocumentFormat <= 19) {
                showToast("Image", "📸");
            }
            store.dispatch('getDocumentFromServer', p.FileName)
        });
        // const EmailKBDataHub = createHubProxy("EmailKBDataHub");
        // EmailKBDataHub.on("update", (p) => {
        //     console.log("🚀 ~ EmailKBDataHub.on ~ p:", p)
        //     p.Items.forEach((item) => {
        //         // console.log("🚀 ~ p.Items.forEach ~ item:", item)
        //         store.commit("addToEmailKnowledgeBase", item);
        //     });
        // });
        connect("EmailKBDataHub", (p) => {
            console.log("🚀 ~ EmailKBDataHub.on ~ p:", p)
            p.Items.forEach((item) => {
                store.commit("addToEmailKnowledgeBase", item);
            });
        });
    }

    return {
        getJWT,
        connectToHubs
    };
}