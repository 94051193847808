<template>
          <div class="groups__display">
              <!-- {{ getGroups }} -->
            <!-- {{getGroups}} -->
            <div class="groups__group"
                v-for="(group, groupName) in getGroups"
                :key="groupName" 
            > 
            <button @click="deleteGroup(group.groupID)" class="group__delete">delete group</button>
            <button @click="addNewGroupMember(group.groupID)" class="group__invite">+Members</button>
               
                <h2 class="header__title"><span class="tymetool__icon"><span>{{ group.emoji }}</span></span>{{ groupName }}</h2>
                <div class="groups__list">
                    <div class="groups__list-row"
                        v-for="(member, i) in group.groupMembers"
                        :key="i"
                    > 
                        <!-- :class="{'other-line': i % 2 === 0}" -->
                 <img class="groups__avatar" v-bind:src="member.avatar"  alt="image" >

                        <div>
                            <div class="group__member-name">{{ member.user }}</div>
                            <div class="groups__status">{{ member.status }}</div>
                        </div>
                        <DxButton
                            class="buttons"
                            icon="remove"
                            @click="removeUserFromGroupAPI({user: member.user, groupID: group.groupID})"
                        />
                        <!-- <button class="group__remove-btn" @click="removeUserFromGroupAPI({user: member.user, groupID: group.groupID})">remove</button> -->
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

import DxButton from 'devextreme-vue/button';

import { useStore } from 'vuex';



import { mapGetters } from 'vuex';
export default {
    components: {
        DxButton
        },
        computed: {
            ...mapGetters(['getGroups']),
        },
        beforeMount() {
            const store = useStore();
             store.dispatch('getUsersGroups', store.getters.getUser.email);
        },
        methods: {
        addNewGroupMember(groupID) {
            const memberToAdd = prompt('Please enter the email address of the person you would like to add');
            console.log(groupID);
            if (!memberToAdd) return

            const groupData = {ID: groupID, members: [memberToAdd]}
            this.$store.dispatch('addUserToGroup', groupData)
        },
        removeUserFromGroupAPI(details) {
            if (confirm(`Are you sure you want to remove ${details.user} from this group`)) {
                this.$store.dispatch('removeUserFromGroup', details)
            }
        },
        deleteGroup(groupID) {
            if (confirm('Are you sure you want to delete this group?')) {
                this.$store.dispatch('deleteGroup', groupID);
            }
        }
    }

}
</script>

<style lang="scss">


.buttons {
    width: fit-content;
    border-radius: 100px;
    text-align: right;
    justify-self: end;
}

.groups__display {
    padding: 3rem;
    // margin: 5rem 5rem 5rem 0;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    // height: 150vh;
    // place-items: start;
    // align-items: end;
    // justify-items: start;
    // grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    // grid-template-rows: auto;

    @media only screen and (max-width: 768px) {
        padding-top:2rem;
        margin: auto;
    }
}

.groups__group {
    border: 1px solid grey;
        box-shadow: 0 0 8px 1px grey;

    width: 20rem;
    height: min-content;
    position: relative;
    margin: 1rem;
}

.groups__list-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // display: flex;
    // justify-content: space-between;
    border-bottom: 1px solid rgb(196, 194, 194);
    padding: .5rem .2rem;
}

.groups__avatar {
    width: 50%;
}

.groups__status {
    font-weight: bold;
}

.group__invite {
    position: absolute;
    top: -.9rem;
    left: -1rem;
    padding: .2rem .4rem;
    border-radius: 1000px;
    border: none;
    background-color: #05D0EB;
    color: #fff;
    transition: all .2s;
    z-index: 10;
    cursor: pointer;

        &:hover {
        background-color: #04bcd4;
        transform: translateY(-2px);
    }
}

.group__delete {
    position: absolute;
    top: -.9rem;
    right: -1rem;
    padding: .2rem .4rem;
    border-radius: 1000px;
    border: none;
    cursor: pointer;
}

.groups__heading {
    text-align: left;
    padding-bottom: .6rem;
}

.header__title {
    background-color: #fff;
    text-align: left;
    padding: 0.4rem;
    font-weight: 400;
    color: #05c3dd;
    border: 1px solid grey;

    & span {
        padding-right: 6px;
    }
}


</style>