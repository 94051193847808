import notify from "devextreme/ui/notify";

export function showToast(message, icon){
    notify(
          {
              message: icon + " " + message, 
              height: 45,
              width: 110,
              minWidth: 110,
              type: 'info',
              displayTime: 3500,
              animation: {
                show: {
                  type: 'fade', duration: 400, from: 0, to: 1,
                },
                hide: { type: 'fade', duration: 40, to: 0 },
              }
          }, 
          { position: "bottom right", direction: "up-push" }
      );
}