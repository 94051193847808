<template>
  <div style="max-width: 90vw; max-height: 90vh">
    <canvas id="the-canvas"></canvas>
  </div>
</template>

<script setup>
import { computed, onMounted, watch, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
// import pdfjsLib from 'pdfjs-dist';
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";
GlobalWorkerOptions.isEvalSupported = false;
const props = defineProps({
  pdfName: {
    type: String,
    required: true,
  },
});

const store = useStore();
const pdfData = computed(() => store.getters.getDocumentById(props.pdfName));

let context;
let canvas;
watch(
  () => props.pdfName,
  (n, o) => {
    clearCanvas();
    loadPdf();
  }
);

function clearCanvas() {
  context.clearRect(0, 0, canvas.width, canvas.height);
}

function binaryStringToUint8Array(binaryString) {
  const length = binaryString.length;
  const array = new Uint8Array(new ArrayBuffer(length));
  for (let i = 0; i < length; i++) {
    array[i] = binaryString.charCodeAt(i);
  }
  return array;
}

function loadPdf() {
  console.log("🚀 ~ loadPdf ~ loadPdf:");

  if (!pdfData.value) return;

  const reader = new FileReader();
  reader.readAsDataURL(pdfData.value.FileBytes);
  reader.onloadend = function () {
    const base64data = reader.result;
    console.log("base64data:", base64data);
    const base64string = base64data.split(",")[1];
    console.log("base64string:", base64string);

    var binaryString = atob(base64string);
    console.log("binaryString:", binaryString);
    var uint8Array = binaryStringToUint8Array(binaryString);
    console.log("uint8Array:", uint8Array);
    const loadingTask = getDocument({ data: uint8Array });

    // var txtDecodeString = atob(pdfData.value.FileBytes);

    // const loadingTask = getDocument({ data: txtDecodeString });

    loadingTask.promise.then((pdf) => {
      console.log("PDF loaded");

      // Fetch the first page
      const pageNumber = 1;
      pdf.getPage(pageNumber).then((page) => {
        console.log("Page loaded");

        const scale = 1.2;
        const viewport = page.getViewport({ scale: scale });

        // Prepare canvas using PDF page dimensions
        canvas = document.getElementById("the-canvas");
        context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          console.log("Page rendered");
        });
      });
    });
  };
}
loadPdf();


onBeforeUnmount(() => {
  // Your function here...
  console.log("Component is about to be destroyed");
  if (context) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    console.log("PDF removed and component is about to be destroyed");
  }
});
</script>
