<template>
  <!-- <ul> -->
    <div>

    <div v-for="(cellData, i) in TagsList" :key="i">
      <div style="display:flex;" class="flex-between">
        <div>
          <img width="30" :src="getIcon(cellData.type)" />
        </div>
        <div v-if="cellData.desc">
          {{ cellData.desc }}
        </div>
      </div>
    </div>
  <!-- </ul> -->
    </div>
</template>

<script>
// import DisplayTagsVue from './DisplayTags.vue'

export default {
  props: {
    params: Object
  },
  setup(props) {

    const TagsList = props.params.data.ModelTags.TagsList

    function getIcon(modelType) {
      try{
        return require(`../../assets/icons/${modelType.toLowerCase()}.png`); //note backticks used for String interpolation
      }
      catch(error){
        console.log(error);
        return  require(`../../assets/icons/email.png`);
      }
    }
    return { TagsList, getIcon }
  }
}
</script>

<style scoped>

.flex-between {
  display: flex;
  border: 2px dotted red;
}


</style>
