const screenWidth = {

    computed: {
      isMobile() {
        if (window.innerWidth > 768) {
          return false;
        } else {
          return true;
        }
      },
      popupWidth() {
        if (window.innerWidth > 768) {
          return "500px"
        } else {
          return "300px"
        }
    },
    popupHeight() {
        if (window.innerWidth > 768) {
          return "400px"
        } else {
          return "210px"
        }
      },
      smallPopupWidth() {
        //   return "80%";
        if (window.innerWidth > 768) {
          return "30%"
        } else {
          return "70%"
        }
      },
    smallPopupHeight() {
        if (window.innerWidth > 768) {
          return "210px"
        } else {
          return "180px"
        }
      }
    }
  }


  export default screenWidth;