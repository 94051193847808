<!-- <template>
  <div ref="creator" class="groups__creator groups__list">
    <form class="groups__creator-inners">
      <div class="flex form__row">
        <div class="form__col-25">
          <label class="groups__label" for="group-name">Group name:</label>
        </div>
        <div class="flex form__col-75">
          <div>
            <span>
              <input
                class="groups__input"
                v-model="newGroupName"
                type="text"
                name="group-name"
              />
            </span>
            <span class="emoji-picker__wrapper">
              <div class="emoji-picker" v-if="showEmojiPicker">
                <emoji-picker
                  @selectedEmoji="selectedEmoji"
                  :showEmojiPicker="showEmojiPicker"
                ></emoji-picker>
              </div>
            </span>
          </div>
     
          <div @click="showEmojiPickerMethod" class="neu-emoji-btn">
            <span>{{ emojiAvatar }}</span>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div class="form__col-25"></div>
      </div>
      <div class="form__row">
        <div class="form__col-25">
          <label class="groups__label" for="members-to-add">Add members:</label>
        </div>
        <div class="form__col-75">
          <Users
            v-for="(member, i) in potentialMembers"
            :index="i"
            :key="i"
            :member="member"
            @selected="addUserToGroup"
            @deselect="removeUserFromGroup"
          />
        </div>
      </div>

      <div class="form__row">
        <form action="addToMemberList">
          <div class="flex">
            <div class="form__col-25">
              <label class="groups__label" for="members-to-add">Email:</label>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="form__col-75">
              <div class="flex">
                <input
                  class="groups__input"
                  v-model="memberToAdd"
                  id="members-to-add"
                  type="text"
                />
                <DxButton icon="add" @click="addToMemberList" />
                <transition name="slide-left">
                  <div v-if="needsToBeEmail">This needs to be an email</div>
                </transition>
              </div>
            </div>
          </div>
        </form>
      </div>
      <br />
      <div>
        <div class="groups__new-group">
          <transition-group name="members">
            <div class="groups__users">{{ getUser.email }}</div>
            <div
              v-for="member in newGroupMembers"
              :key="member"
              class="groups__users"
            >
              {{ member }}
            </div>
          </transition-group>
        </div>
        <br />
        <input
          class="groups__create-btn"
          @click.prevent="createNewGroup"
          type="submit"
          value="Create group"
        />
      </div>
    </form>

  </div>
</template> -->

<template>
  <div>
    <form>
      <div class="section flex">
        <label for="group-name">Group name: </label>
        <input v-model="newGroupName" type="text" id="group-name" />
        <label>Group emoji: </label>
        <span>

          <button class="neu-emoji-btn" @click="showEmojiPickerMethod">{{ emojiAvatar }}</button>
        </span>

        <span class="emoji-picker__wrapper">
          <div class="emoji-picker" v-if="showEmojiPicker">
            <emoji-picker
              @selectedEmoji="selectedEmoji"
              :showEmojiPicker="showEmojiPicker"
            ></emoji-picker>
          </div>
        </span>
      </div>
      <div class="member-list section">
        <div>
          <label for="members-to-add">Add members: </label>
        </div>

        <div v-for="(member, i) in potentialMembers" :key="i">
          <User
            :member="member"
            @selected="addUserToGroup"
            @deselect="removeUserFromGroup"
          />
        </div>
      </div>
      <div class="section">
        <label for="members-to-add">Email: </label>
        <input v-model="memberToAdd" id="members-to-add" type="text" />
        <button @click="addToMemberList">Add</button>
        <div v-if="needsToBeEmail">This needs to be an email</div>
      </div>
      <div class="section">
        <div>
          <label v-if="newGroupName" for=""
            >{{ emojiAvatar }}&nbsp;{{ newGroupName }}:
          </label>
        </div>
        <div class="member-list">
          <div
            class="new-member"
            v-for="member in newGroupMembers"
            :key="member"
          >
            {{ member }}
          </div>
        </div>
      </div>
      <div class="flex-right">
        <button @click.prevent="createNewGroup">Create group</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.flex-right {
  display: flex;
  justify-content: flex-end;
}

.new-member {
  margin: 3px;
  padding: 0 4px;
  border: 1px solid rgb(220, 220, 220);
}

form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 30px; /* Increased spacing between form elements */
}

label {
  font-weight: bold;
  margin-bottom: 10px; /* Increased spacing between label and input */
}

input[type="text"] {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
}

/* button {
  padding: 10px 20px;
  background-color: #05d0eb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

/* button:hover {
  background-color: #05d0eb;
} */

.member-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px; /* Reduced spacing between <User> components */
}

.neu-emoji-btn {
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f8f9fa;
  box-shadow: 6px 6px 12px #b8b9ba, -6px -6px 12px #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neu-emoji-btn span {
  font-size: 140%;
}

.emoji-picker {
  position: absolute;
  z-index: 100;
}
</style>

<script>
import { mapGetters } from "vuex";
import EmojiPicker from "../UserSelectable/EmojiPicker.vue";
import DxButton from "devextreme-vue/button";
import User from "./Users";

// import Tester from './Tester.vue';

export default {
  components: {
    EmojiPicker,
    DxButton,
    User,
    // Tester
  },

  data() {
    return {
      emojiAvatar: "👥",
      showEmojiPicker: false,
      needsToBeEmail: false,
      newGroupName: "",
      newGroupMembers: [],
      memberToAdd: "",
      potentialMembers: [
        { member: "monim@lanternsoft.com", checked: false },
        { member: "matias@lanternsoft.com", checked: false },
        { member: "james@lanternsoft.com", checked: false },
        { member: "zachary@lanternsoft.com", checked: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getGroups", "getUser"]),
  },
  methods: {
    closeEmojiPicker(e) {
      if (e?.target.closest(".neu-emoji-btn")) return;
      // console.log(e.target);
      if (e?.target.closest(".emoji-mart")) return;
      this.showEmojiPicker = false;
      document.removeEventListener("click", this.closeEmojiPicker);

      //    if (this.showEmojiPicker) {
      //    }
    },
    showEmojiPickerMethod() {
      this.showEmojiPicker = true;
      setTimeout(() => {
        document.addEventListener("click", this.closeEmojiPicker);
      });
    },
    selectedEmoji(emoji) {
      console.log(emoji);
      this.emojiAvatar = emoji;
      this.showEmojiPicker = false;
      this.closeEmojiPicker();
    },
    addToMemberList() {
      // e.preventDefault();
      if (!this.memberToAdd.includes("@")) {
        this.needsToBeEmail = true;
      } else {
        this.needsToBeEmail = false;
        const newMember = { member: this.memberToAdd, checked: true };
        this.potentialMembers.push(newMember);
        this.addUserToGroup(newMember);
        console.log(this.newGroupMembers);
        this.memberToAdd = "";
      }
    },
    addUserToGroup(user) {
      this.newGroupMembers.push(user.member);
    },
    removeUserFromGroup(user) {
      this.newGroupMembers = this.newGroupMembers.filter(
        (groupMember) => groupMember !== user.member
      );
    },
    createNewGroup() {
      const newGroupDetes = {
        groupName: this.newGroupName,
        members: this.newGroupMembers,
        emoji: this.emojiAvatar,
      };
      console.log(newGroupDetes);
      this.$store.dispatch("createGroup", newGroupDetes);
    },
  },
};
</script>

<!-- <style lang="scss">
.neu-emoji-btn {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 42px;
  background: linear-gradient(145deg, #fbfbfb, #d4d4d4);
  box-shadow: 6px 6px 12px #7d7d7d, -6px -6px 12px #ffffff;
  position: relative;
  & span {
    position: absolute;
    font-size: 140%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.emoji-picker {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 2rem;

  &__wrapper {
    // position: relative;
    top: 0rem;
    left: 0rem;
  }
}

.emoji-preview {
  padding-left: 1rem;
  font-size: 220%;
  text-align: left;
}

.other-line {
  background-color: #acaaaa;
}

.members-enter-from {
  opacity: 0;
  transform: translateY(-100px);
}

.members-enter-active {
  transition: all 0.4s ease-out;
}

.members-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.members-leave-active {
  transition: all 0.2s;
  position: absolute;
}

.members-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}

.members-move {
  transition: transform 0.4s ease;
}

.group__remove-btn {
  padding: 0rem;
}

.groups__add-member {
  padding: 0.4rem 0.6rem;
  margin: auto;

  &:hover {
    background-color: rgb(180, 180, 180);
  }
}

.group__member-name {
  padding-top: 0.6rem;
  padding-right: 1rem;
}

.groups__input {
  padding: 0.5rem;
  border: 1px solid grey;
  // background-color: rgb(228, 228, 228);

  &:focus {
    outline: none;
    border-bottom: 1px solid blue;
  }
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-left-active {
  transition: all 2.2s ease-in;
}

.slide-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.groups__container {
  background-color: var(--bg-color);
  background-size: cover;
  display: grid;
  grid-template-columns: 1.4fr 2fr;
  grid-column-gap: 10rem;
  font-size: 80%;

  @media only screen and (max-width: 600px) {
    padding-top: 2rem;
    grid-template-columns: 1fr;
    grid-column-gap: 0rem;
  }
}

.groups__creator {
  position: relative;
  width: 90%;
  margin: auto;
  // margin: 4rem 0rem 5rem 5rem;
  height: min-content;
  // background-color: var(--bg-color);
  // box-shadow: 0 0 8px 1px grey;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}

.groups__creator-inners {
  // padding: 1rem 1rem 0 1rem;
  // background-color: var(--bg-color);
  // color: rgb(39, 39, 39);
  // border-left: 1px solid grey;
  // border-right: 1px solid grey;
  // border-bottom: 1px solid grey;
}

.groups__new-group {
  // box-shadow: inset 0rem 0rem 1rem 1rem rgba(0, 0, 0, 0.171);
  padding: 1rem;
  margin-right: 0.9rem;
  background-color: #fff;
  color: #2a2a2a;
  border: 1px solid grey;
  min-height: 8rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  &-member {
    padding: 0.15rem;
    margin: 0.1rem;
    border: 1px solid grey;
    height: min-content;
  }
}

.groups__list {
  background-color: #fff;
}

.groups__add-member {
  // margin: 0rem 0;
}

.groups__label {
}

.group__invite:active {
  transform: translateY(0);
}

.groups__list-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // display: flex;
  // justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid rgb(196, 194, 194);
  padding: 0.5rem 0.2rem;
}

.groups__create-btn {
  // transform: translateX(10rem);
  margin: 0.6rem;
  color: #fff;
  font-size: 110%;
  padding: 0.4rem 0.6rem;
  background-color: #05d0eb;
  border-radius: 100px;
  border: none;
  outline: none;
  cursor: pointer;
}

.groups__create-btn:hover {
  background-color: #00e1ff;
}

.flex  {
  display: flex;
  align-items: center;
  margin-bottom: .6rem;
}
</style> -->
