<template>
  <div style="display: flex">
    <div class="select-box-holder">
      <div class="selected-tag">
        <div
          style="display: flex; justify-content: space-between"
          v-if="selectedValue"
        >
          <div>
            <DisplayTagsVue
              :size="40"
              :tags="[{ type: selectedValue, desc: selectedValue }]"
            />
          </div>
        </div>
        <div class="select-tag" v-else>Select&nbsp;tag...</div>
      </div>
      <DxSelectBox
        :value="{ type: selectedValue, desc: selectedValue }"
        width="400"
        height="46"
        :dataSource="formattedDataSource"
        :grouped="false"
        @value-changed="emitE"
        :disabled="disabled"
        placeholder=""
      >
        <template #item="{ data }">
          <DisplayTagsVue :tags="[data]" :size="30" />
        </template>
      </DxSelectBox>
    </div>
    <div class="deselect-tags" v-if="selectedValue" @click="removeTag">
      <i class="fa fa-times"></i>
    </div>
    <div class="placeholder" v-else>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import DxSelectBox from "devextreme-vue/select-box";
import DisplayTagsVue from "../email/DisplayTags.vue";

const props = defineProps({
  dataSource: Array,
  disabled: Boolean,
  selectedValue: String,
});

const emit = defineEmits(["selectedItem"]);

let formattedDataSource = ref([]);

function showExp(e) {
  return e.desc;
}

function removeTag() {
  emitE({ value: { type: "" } });
}

function emitE(e) {
  emit("selectedItem", e.value.type);
}
onMounted(() => {
  formattedDataSource.value = props.dataSource.map((el) => ({
    type: el.tag,
    desc: el.tag,
  }));
});

emitE({ value: { type: props.selectedValue } });
</script>

<style lang="scss" scoped>

.placeholder {
  width: 1.75rem;
}

.select-box-holder {
  position: relative;
  // background-color: #ffffff !important;

}

.select-box-holder .dx-texteditor.dx-editor-filled {
  background-color: #ffffff !important;
}

.select-tag {
  position: absolute;
  top: 7px;
  left: 8px;
  font-size: 1.3rem;
}

.selected-tag {
  z-index:2;
  position: absolute;
  top: 0.2rem;
}

.deselect-tags {
  color: red;
  font-size: 2rem;
  padding: 2px 0 0 2px;
  text-transform: bold;
  transform: translateY(3px);
}
</style>
