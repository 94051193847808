<template>
  <div>
    <div class="emoji__picker" v-if="showEmojiPicker">
      <div class="row">
          <Picker :data="emojiIndex" @select="selectEmoji" />
      </div>
    </div>
  </div>
</template>

<script>

import data from "../../npm/emoji-mart-vue-master/data/all.json";
import { Picker, EmojiIndex } from "../../npm/emoji-mart-vue-master/src";
import "../../npm/emoji-mart-vue-master/css/emoji-mart.css";
let emojiIndex = new EmojiIndex(data);

export default {
    props: {
        showEmojiPicker: Boolean,
    },
    components: { 
        Picker,
    },
    data() {
        return {
          emojiIndex,
        };
    },
    methods: {
    selectEmoji(emoji) {
      if (!emoji.native) return;
      this.$emit('selected-emoji', emoji.native);
    }
  }
}
</script>

<style scoped lang="scss">

.emoji__picker {
  // position: absolute;
}  

.emoji-mart-bar-preview {
  display: none;
}

.emoji-mart-category-label {
  display: none;
}

</style>