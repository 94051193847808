<template>
  <div class="tree-view-container">
    <!-- <button @click="getDocumentFromServer">
      call server
    </button> -->
    <!-- <PDFViewer :pdfName="'Howtonne'"/> -->
    <!-- THIS SIMULATES ADDING OBJECTS TO THE KNOWLEDGE BASE WHEN SIGNAL R IS NOT RUNNING -->
    <!-- <button
      @click="
        addObjectsToKBFlatStructure(
          {
            Id: '1',
            ParentId: null,
            Name: 'User',
            Icon: 'user',
            UH: 'User',
          }  )
      "
    >
      add to kb
    </button> -->
    <!-- {{ treeViewData }} -->
    <!-- {{ store.getters.documents }}
    {{ treeViewData }} -->
    <DxTreeView
      :dataSource="treeViewData"
      parent-id-expr="ParentId"
      display-expr="Name"
      :expandNodesRecursive="false"
      ref="treeViewInstance"
      id="simple-treeview"
      :width="1000"
      data-structure="plain"
      key-expr="Id"
      @item-click="selectItem"
      @item-expanded="maintainScrollPosition"
    >
      <template #item="item">
        <div class="flex">
          <span v-if="item.data.Icon">
            <ModelTags
              :TagsList="[item.data.Icon]"
              :LargeIcon="item.data.ParentId == null"
            />
          </span>
          <div class="text">
            <span>
              {{ item.data.Name }}
            </span>
          </div>
          <span style="padding-left: 10px" v-if="item.data.ModelTags">
            <ModelTags
              :LargeIcon="false"
              :TagsList="item.data.ModelTags.TagsList.map((tag) => tag.type)"
            />
          </span>
        </div>
      </template>
    </DxTreeView>

    <!-- <DxPopup
      :visible="true"
      :closeOnOutsideClick="true"
      :showTitle="false"
      :width="800"
      :height="600"
      :dragEnabled="true"
      :closeOnOutsideClick="true"
      :showCloseButton="true"
      :position="{ my: 'center', at: 'center', of: window }"
      @close="closeCard"
      /> -->

    <!-- <DxPopup
        :height="'90%'"
        :width="'90%'"
        :show-title="true"
        :drag-enabled="false"
        :close-on-outside-click="true"
        v-model:visible="showCard"
        :show-close-button="true"
        
        > -->
    <!-- @close="closeCard"
        :showCard="showCard" -->
    <!-- <EmailPopup @close="closeCard" :showCard="showCard || showDocument"> -->

    <!-- :height="500"
      :width="500" -->
    <DxPopup
      :on-hiding="closeCard"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      v-model:visible="showPopup"
      :show-close-button="true"
      :autoResizeEnabled="true"
      :resizeEnabled="true"
    >
      <div v-show="showCard">
        <EmailCard :cardData="[emailCardData]" />
      </div>
      <div v-show="showDocument">
        <!-- <div v-if="documentType === 'pdf'">
          <PdfViewer :pdfName="documentName"> </PdfViewer>
        </div>
        <div v-if="documentType === 'image'">
          <ImageViewer :imageName="documentName" />
        </div>
        <div v-if="documentType === 'calendarevent'">
          <Calendar :documentName="documentName" />
        </div>
        <div v-if="documentType === 'csv'">
          <CSVViewer
            :documentName="documentName"
        />
        </div> -->
        <DocumentViewer
          :documentType="documentType"
          :documentName="documentName"
        />
        <!-- <PDFViewer :documentName="documentName" /> -->
      </div>
    </DxPopup>

    <!-- </EmailPopup> -->
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, nextTick } from "vue";
import { useStore } from "vuex";
import DxTreeView from "devextreme-vue/tree-view";
import ModelTags from "./TagRenderer.vue";
import EmailCard from "./EmailCard.vue";
import PDFViewer from "../DocumentDisplay/PdfViewer.vue";
import DxPopup from "devextreme-vue/popup";
import EmailPopup from "./EmailPopup.vue";
import DocumentViewer from "../DocumentDisplay/DocumentViewer.vue";
import PdfViewer from "../DocumentDisplay/PdfViewer.vue";
import ImageViewer from "../DocumentDisplay/ImageViewer.vue";
import Calendar from "../calendar/scheduler/DevExCal.vue";
// import CSVViewer from '../DocumentDisplay/CSVViewer.vue';

let showPopup = computed(() => showCard.value || showDocument.value);

const treeViewInstance = ref(null);

const store = useStore();
function getDocumentFromServer() {
  store.dispatch(
    "getDocumentFromServer",
    "0474_LO_AW_menu_GFVegan_BAND_A_425mm_x_305mm_WEB_2_VEGAN"
  );
}

import { createConnection } from "../../../core/signalr/clients/EmailDataHub";

// const { myHub } = createConnection(store.getters.modelHub, "EmailKBDataHub");

let treeViewData;

const loadChildNodes = (node) => {
  return;
};

// function getScrollPosition() {
//   let el = document.querySelector(".tree-view-container");
//   if (!el) return;
//   const treeViewElement = el.closest(".dx-scrollable-container");

//   return treeViewElement?.scrollTop;
// }

treeViewData = computed(() => {
  maintainScrollPosition();
  return [...store.getters.emailKnowledgeBase];
});

watch(treeViewData, (newVal, oldVal) => {
  treeViewInstance.value.instance.option("items", newVal);
});
// let scrollPosition = ref(0);
async function maintainScrollPosition() {
  let el = document.querySelector(".tree-view-container");
  if (!el) return;
  const treeViewElement = el.closest(".dx-scrollable-container");

  const scrollTop = treeViewElement?.scrollTop;

  await nextTick();
  if (treeViewElement) {
    treeViewElement.scrollTop = scrollTop;
  }
}

// function addKBObjectsFromHardCodedStore() {
//   treeViewData = computed(() => store.getters.emailKnowledgeBase);
//   addKBItemsToTreeView(treeViewData.value);
// }
// FOR USE IF SIGNAL R IS NOT RUNNING
// addKBObjectsFromHardCodedStore();

// setTimeout(() => {
//   addKBObjectsFromHardCodedStore();
// }, 30000);

function addObjectsToKBFlatStructure(KBObjects) {
  store.commit("addToEmailKnowledgeBase", KBObjects);

  //   const KBData = treeViewInstance.value.instance.getDataSource()._items

  //   treeViewInstance.value.instance.option('items', [...KBData, ...KBObjects])
}

function getImageUrl(fileName) {
  if (!fileName) return;
  console.log(fileName);
  return require(`../../assets/icons/${fileName.toLowerCase()}.png`);
}

let emailCardData = ref({});
let showCard = ref(false);
function closeCard() {
  showCard.value = false;
  showDocument.value = false;
}

let showDocument = ref(false);
let documentName = ref("");

let documentType = ref("");

const checkIfDocument = (item) => {
  console.log("🚀 ~ checkIfDocument ~ item:", item);

  if (item.itemData.Icon === "pdf") {
    showDocument.value = true;
    documentType.value = "pdf";
    documentName.value = item.itemData.Name;
  } else if (item.itemData.Icon === "pics") {
    documentType.value = "image";
    documentName.value = item.itemData.Name;
    showDocument.value = true;
  } else if (item.itemData.Icon === "calendarevent") {
    documentType.value = "calendarevent";
    documentName.value = item.itemData.Name;
    showDocument.value = true;
  } else if (item.itemData.Icon === "csv") {
    documentType.value = "csv";
    documentName.value = item.itemData.Name;
    showDocument.value = true;
  } else {
    showDocument.value = false;
  }

  // if (item.ModelTags) {
  //   return item.ModelTags.TagsList.some((tag) => tag.type === "Document");
  // }
};

function selectItem(e) {
  maintainScrollPosition();
  // scrollPosition.value = getScrollPosition();

  e.event.preventDefault();

  checkIfDocument(e);
  if (e.node.items.length > 0) return;

  const selectedItem = e.itemData;

  console.log("Selected leaf node:", selectedItem);
  console.log(e.itemData);
  let data = e.itemData;
  data.Subject = e.itemData.Name;
  emailCardData.value = data;
  showCard.value = true;
}
</script>

<style>
.dx-popup-wrapper .dx-popup-normal {
  width: fit-content !important;
  height: fit-content !important;
  display: inline-block;
  width: auto;
  height: auto;
}
.tree-view-container .flex {
  overflow-y: auto;
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.tree-view-container {
  position: relative;
  height: 100%;
}

.text {
  padding: 0 0 4px 10px;
}
</style>
