<template>
  <div>
    <!-- {{ store.getters.extractedTags }} -->
    <div class="tagsContainer">
      <div
        style="margin: 4rem"
        v-for="(type, key) in [
          ...store.getters.extractedTags,
        ]"
        :key="key"
      >
        <DisplayTagsVue
          @click="goToEmails(type.tag)"
          :size="160"
          :tags="[{ type: type.tag, desc: type.count }]"
          :hoverAndClickStyling="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import DisplayTagsVue from "./DisplayTags.vue";

import { createConnection } from "../../signalr/clients/EmailDataHub";

const store = useStore();
const router = useRouter();

let totalTags = ref({});

function goToEmails(type) {
  // router.push(`/profile#/profile?modelGroup=emails&widgetViews=email`);
  router.push('emails')
  store.commit("tagsFilter", type);
}
</script>

<style scoped>
.tagsContainer {
  display: flex;
  flex-wrap: wrap;
}
</style>
