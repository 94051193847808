<template>
  <div style="width:300px;">
    <div class="search-bar">
      <div class="search-icon">
        <i class="fa fa-search"></i>
      </div>
      <input
        type="text"
        class="search-input"
        placeholder="Search"
        v-model="searchText"
        @input="search"
      />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  transition: all 0.3s ease;
  width: 100%; /* Make the search bar take up the full width of its container */
  max-width: 600px; /* But don't let it get bigger than 600px */
  margin: 0 auto; /* Center the search bar if its container is wider than 600px */
}

.search-bar:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.search-icon {
  margin-right: 10px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
}
</style>
