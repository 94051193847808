<template>
  <div class="container" @hover="showOptionsFunc">
    <div class="show-on-hover delete-tag" @click="deleteTag">
      <i class="fa fa-times"></i>

    </div>
    <DisplayTags :size="50" :tags="[{ type: props.tag.tag, desc: props.tag.Names[0] }]" :hoverAndClickStyling="true" />
    <br>
    <div>
      <div v-for="description in props.tag.Descriptions" :key="description">
        {{ description }}<i @click="removeTagDescription(description)" class=" fa fa-times"></i>
      </div>
      <div class="show-on-hover" style="display:flex;">
        <input placeholder="Add tag description..." v-model="tagDescription"/>
        <div @click="addTagDescription">
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DisplayTags from "./DisplayTags.vue";
import { ref } from 'vue';

const emits = defineEmits(['deleteTag']);

function deleteTag() {
  emits('deleteTag', props.tag);
}

const props = defineProps({
  tag: Object,
});

let showOptions = ref(true)
function showOptionsFunc() {
  showOptions.value = !showOptions.value;
}
let tagDescription = ref('');
function addTagDescription() {
  props.tag.Descriptions.push(tagDescription.value)
  tagDescription.value = "";
}

function removeTagDescription(desc) {

  let index = props.tag.Descriptions.indexOf(desc);
  props.tag.Descriptions.splice(index, 1);
}


</script>

<style scoped lang="scss">
.delete-tag {
  z-index:2;
  /* display: none; */
  position: absolute;
  top: -10px;
  right: -6px;
  cursor: pointer;
}

.show-delete {
  display: block;
}
.show-on-hover {
  opacity: 0;
}

.container {
  &:hover {
    .show-on-hover {
      opacity: 1;
    }
  }
}

</style>
