// To see hub go to http://localhost:4446/signalr/hubs

function createConnection(hubReference, hubProxy, group="GroupX") {
  // console.log("🚀 ~ createConnection ~ hubReference, hubProxy, group:", hubReference, hubProxy, group)
  try {

  
  var connection = $.hubConnection(`${hubReference}/signalr/hubs`, {
    useDefaultPath: false,
  });

  var myHub = connection.createHubProxy(hubProxy);
  connection
    .start()
    .done(function () {
      myHub.invoke("JoinGroup", group).done((task1) => {
        console.log(task1);
        if (task1) {
        } else {
          //message sent wait to receive
        }
      });

      // console.log(connection);
      console.log("Now connected to " + hubProxy+ ", connection ID=" + connection.id);
    })
    .fail(function (e) {
      console.log(e);
    });

  return { connection, myHub };
  } catch(err) {
    console.error(err);
    return {}
  }
}

export { createConnection };
