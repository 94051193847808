<template>
      <div style="width:100%;padding:20px;position:relative;">
          Trying to verify...

          <div v-if="messageFromServer">
            {{ messageFromServer }}
            </div>

      </div>
</template>

<script>

import {  useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
  name: 'VerifyEmail',
  props: {
    email: String,
    token: String
  },
  setup(props) {

    const messageFromServer = ref('');

    const store = useStore();
    
    const router = useRouter();


    try {

      console.log(props.email);

      // let apiKey = document.getElementById("CONTENT_API_KEY").value;
      let apiKey = 'z7t3r4e9872mn65ty47dt3erstyw543i9';

        fetch(`${store.getters.VUE_APP_APIURL}/Verify?emailAddress=` + props.email, 
        {
                headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'ApiKey': apiKey,
                        'Authorization' : 'Bearer ' +  props.token
                },
                method: "POST"
        })
        .then(response => {

                if(response.ok){

                  return response.json();

                } else {
                  messageFromServer.value = "Server returned " + response.status + " : " + response.statusText;
                  console.log(messageFromServer.value);
                }                
              }, 
              reject =>  console.log('No data available - please try again soon - ' + reject)
        )
        .then(json => {

            if (json){
                alert ('Verified! Please log in to start creating content.')
                router.push("/login-form");

                // router.push("/login-form");
              }
            }          
          )                  
         .catch(err => {
              //alert("Incorrect login" + err.message + "  name  = " + err.name);
                console.log(err);
                // return {
                //   isOk: false,
                //   message: "Verification failed"
                // };
            });

    }
    catch {
      // return {
      //   isOk: false,
      //   message: "Verification failed"
      // };
    }
    
    return { messageFromServer };
  }
};
</script>

