"use strict";


// USING AS TEMPORARY SOLUTION UNTIL C# BLOCKING IS SORTED
// async function wait(ms) {
//   console.log('here');
//   return new Promise(resolve => {
//     setTimeout(resolve, ms);
//   });
// }




export default {
    logUserIn(context, user) {
    context.commit('setUserEmail', user);
    context.dispatch('getLinkedCalendars', user);
    // context.dispatch('getOwnersCalendars', user);
 },

 async newUserSetupFlow(context, user) {
    // console.log(user);
    const userDetails = {
      name: user,
      ID: user
    }
    
    // const logInDetes = {
    //   email:   
    //   password: 
    // }
    // await context.dispatch('logIn', )

    // THIS WILL BE REPLACED WITH A PROPER SERVER SIDE CALENDAR CREATION. 

    // await context.dispatch('logUserIn', user);

    await context.dispatch('createCalendar', userDetails);
 },

 
  async createCalendar(context, owner) {

    const apiKey = context.getters.apiKey;
    
    // console.log(owner.ID, owner.name);

     console.log(owner);
    
    const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/Calendar/CreateCalendar?ownerEmail=${(owner.ID).toString()}&description=${owner.name}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'ApiKey': apiKey,
        'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
    },
      method: "GET",
    });

    const newCal = await response.json();
    // console.log(newCal);

    return newCal.UID;
    // context.dispatch('getOwnersCalendars', owner.ID);
  },


  async getOwnersCalendars(context, user) {

    // const apiKey = context.getters.apiKey;
    // const apiKey = 'z7t3r4e9872mn65ty47dt3erstyw543i9';
    const apiKey = context.getters.apiKey;

    const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/GetOwnersCalendars?ownerEmail=${user}&includeEvents=true`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'ApiKey': apiKey,
        'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
      },
        method: "GET",
    })

    
    if (!response.ok) {
      // console.log(response);
      context.dispatch('checkIfTokenHasExpired', response);
      // console.log(response);
    } 

    const ownersCal = await response.json();
    console.log("🚀 ~ file: actions.js:93 ~ getOwnersCalendars ~ ownersCal:", ownersCal)

    // console.log('owners cal.... ' + ownersCal);

    // This is not used anymore because multiple calendars were being created for each user when the api failed.
    // if (!ownersCal[0]) return context.dispatch('createCalendar', {name: user, ID: user});
    if (!ownersCal[0]) return console.error('failed to fetch users calendar');

    context.commit('setUserUID', ownersCal[0].UID);


    // console.log(eventsList);

    // const tymeToolUser = {
    //   members: [
    //     {
    //       text: ownersCal[0].OwnerEmail,
    //       id: ownersCal[0].OwnerEmail,
    //       discipline: ownersCal[0].OwnerEmail,
    //     }
    //   ]
    // }

    // context.commit('setPersonalCalendar', tymeToolUser);
    // context.commit('setEvents', eventsList);
    // await context.dispatch('getUsersGroups', context.getters.getUser.email);
    // await context.dispatch('getLinkedCalendars', user);
  },
  async getEventsAndCalendarData(context) {
    console.log("🚀 ~ file: actions.js:274 ~ getEventsAndCalendarData ~ context:", context)
    
    context.dispatch("getOwnersCalendars", context.getters.getUser.email);
    await context.dispatch("getUsersGroups", context.getters.getUser.email);
    await context.dispatch("getLinkedCalendars", context.getters.getUser.email);
  },




  async getLinkedCalendars(context, user) {

    // const apiKey = context.getters.apiKey;

    const apiKey = context.getters.apiKey

    // console.log(apiKey);
    // console.log(context.getters.getUser.bearerToken);

    const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/LinkedCalendar/GetLinkedCalendars?emailorgroupID=${user}&includeEvents=true`, {
      headers: {
        'Accept': 'application/json',
        'ApiKey': apiKey,
        'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken,
      },
      method: "GET",
    })
    console.log("🚀 ~ getLinkedCalendars ~ response:", response)

    if (!response.ok) {
     return context.dispatch('checkIfTokenHasExpired', (response));
      // console.log(response);
    } 

    const calendars = await response.json();

    const allEvents = [];
    const allCalsAndEvents = {};

    calendars.forEach(calendar => {

      allCalsAndEvents[calendar.Description] = {};
      allCalsAndEvents[calendar.Description].events = [];
      allCalsAndEvents[calendar.Description].calUID = calendar.UID;
      console.log(calendar);

      if ((Object.values(calendar.Events)).length !== 0) {

      const eventsArr = Object.values(calendar.Events);    

      eventsArr.forEach(event => {
        const attendees = [];
        event.Attendees.forEach(attendee => {
          attendees.push(attendee.EmailIdentity.EmailAddress)
        })
        const createdEvent = createCalEvent(event.Summary, calendar.Description, event.DtStart, event.DtEnd, attendees, calendar.UID, event.UId);

        allCalsAndEvents[calendar.Description].events.push(createdEvent);
        allEvents.push(createdEvent)
        })
      }
    })
    // console.log(allEvents);
    // console.log(allCalsAndEvents);
    context.commit('setLinkedEvents', allEvents);
    context.commit('setAllCalsAndEvents', allCalsAndEvents);








    const currentGroupName = context.getters.getCurrentGroup.text
    const groupEvents = context.getters.getAllCalsAndEvents[currentGroupName]?.events;
    context.commit('newSetGroupEvents', groupEvents);
  },

  async addEventToCalendar(context, details) {

    const apiKey = context.getters.apiKey;
  
    const sendEmail = details.shift();
    const calUID = details.shift();
    const attendees = details.shift();

    // console.log('logged in user is ' + context.getters.getUser.email);

    console.log(sendEmail, calUID, attendees);

    function Event(start, end, tzOffset, summary, description, location, eventUId) {
    // this.sendEventInvite = sendEmail,
    // this.Alarms = [],
    // this.UId = Date.now(),
    // this.DtStamp = Date.now(),
      this.Organiser = {
        EmailAddress: context.getters.getUser.email,
        DisplayName: context.getters.getUser.email,
        },
      this.Attendees = [],
      this.DtStart = start,
      this.DtEnd = end,
      this.Summary = summary,
      this.Description = description,
      this.EventTz = tzOffset,
      this.Location = location
      // this.UId = eventUId
    }

      var newEvent = new Event(...details);

      // console.log(newEvent);

      if (attendees.length > 0) {
      for (const attendee of attendees) {
        newEvent.Attendees.push(
          {
            EmailIdentity: {
              EmailAddress: attendee,
              DisplayName: attendee
            },
            Status: "NEEDS-ACTION",
            Role: "REQ-PARTICIPANT"
          }
        );
      }
    }

      const linkToTheseCals = [];

      if (newEvent.Attendees.length >= 0) {

      newEvent.Attendees.forEach(async attendee => {
        // console.log(attendee);
        const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/GetOwnersCalendars?ownerEmail=${attendee.EmailIdentity.EmailAddress}`, {
          headers: {
            "Accept": "application.json",
            'ApiKey': apiKey,
            'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
          }
        })

        if (!response) return;

        const owner = await response.json();
        
        if (owner === []) return;

        linkToTheseCals.push(owner[0].UID);
        });
      }

      function createCalEventRequest(Event, calendarUID, CalendarRefUIDs, SendEventInvite) {
        this.Event = Event,
        this.calendarUID = calendarUID[0],
        this.CalendarRefUIDs = CalendarRefUIDs,
        this.SendEventInvite = SendEventInvite
      }

      // console.log(calUID);
      const calendarEventRequest = new createCalEventRequest(newEvent, calUID, linkToTheseCals, sendEmail);
        //  const calendarEventRequest = new createCalEventRequest(newEvent, calUID, linkToTheseCals, false);
        console.log(calendarEventRequest);
      const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/Calendar/AddEventToCalendar`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
          },
          method: "POST", 
          body: JSON.stringify(calendarEventRequest),
      })

      // console.log(response);

//       _createCalEventReques {Event: _Event, calendarUID: undefined, CalendarRefUIDs: Array(0), SendEventInvite: false}
      // CalendarRefUIDs: []
      // Event: _Event
      // Attendees: []
      // Description: undefined
      // DtEnd: Mon Aug 01 2022 18:00:00 GMT+0100 (British Summer Time) {}
      // DtStart: Mon Aug 01 2022 08:00:00 GMT+0100 (British Summer Time) {}
      // EventTz: -60
      // Location: null
      // Organiser: {EmailAddress: 'zachary@lanternsoft.com', DisplayName: 'zachary@lanternsoft.com'}
      // Summary: "test7"
      // [[Prototype]]: Object
      // SendEventInvite: false
      // calendarUID: undefined
      // [[Prototype]]: Object

//       _createCalEventReques {Event: _Event, calendarUID: undefined, CalendarRefUIDs: Array(0), SendEventInvite: false}
      // CalendarRefUIDs: (2) ['20220119_1648_554879f88b38-83e8-4f10-afbc-8f974f', '20220119_1648_554879f88b38-83e8-4f10-afbc-8f974f']
      // Event: _Event {Organiser: {…}, Attendees: Array(2), DtStart: Thu Aug 11 2022 08:00:00 GMT+0100 (British Summer Time), DtEnd: Thu Aug 11 2022 18:00:00 GMT+0100 (British Summer Time), Summary: 'test4', …}
      // SendEventInvite: false
      // calendarUID: undefined
      // [[Prototype]]: Object




      if (!response.ok) {
        context.dispatch('checkIfTokenHasExpired', (response));
        return alert('something went wrong');
      } 

      if (response.ok) {
        // console.log(context.getters.getPersonalCalendar);
        // context.dispatch('getGroupEvents', context.getters.getPersonalCalendar.id);
        // context.dispatch('getOwnersCalendars', context.getters.getUser.email);
        // context.dispatch('getGroupEvents', context.getters.getCurrentGroup.calUID);

        await context.dispatch('getLinkedCalendars', context.getters.getUser.email);

        
        
        context.commit('setNotifyMessage', {
          message: 'event saved'
        })
        const currentGroupName = context.getters.getCurrentGroup.text
        const groupEvents = context.getters.getAllCalsAndEvents[currentGroupName]?.events;
        context.commit('newSetGroupEvents', groupEvents);

        
        // this.$store.commit('newSetGroupEvents', selectedGroup.events);
      }


  },

  async RemoveEventFromCalendar(context, calAndEventUIDs) {

    const apiKey = context.getters.apiKey;

    // console.log(calAndEventUIDs)
    const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/Calendar/RemoveEventFromCalendar?calUID=${calAndEventUIDs.calUID}&evntUID=${calAndEventUIDs.eventUID}&deleteEvnt=true`, {
      headers: {
        "Accept": "application/json",
        'ApiKey': apiKey,
        'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
      },
      method: "DELETE"
    })

    if (!response.ok) {
      context.dispatch('checkIfTokenHasExpired', (response));
      context.commit('setNotifyMessage', {
        message: 'failed to delete event from calendar',
        isOk: false
      })
      // alert('failed to delete event from calendar');
    } 
    if (response.ok) {
      context.dispatch('getLinkedCalendars', context.getters.getUser.email);
      context.commit('setNotifyMessage', {
        message: 'Event deleted',
        isOk: false
      })
    } 
    // alert('event deleted');
  },



  ////////////////////////////////// GROUPS ////////////////////////////////


  async createGroup(context, group) {

    const apiKey = context.getters.apiKey;

     const user = context.getters.getUser.email;

    //  console.log(user);

      const owner = {
        IsHuman: true,
        DisplayName: user,
        EmailAddress: user,
        Avatar: group.avatar,
      }
  
      const response = await fetch (`${context.getters.calendarAPI}/GroupsAPI/CreateGroup?groupName=${group.groupName}&emoji=${group.emoji}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        },
        method: "POST",
        body: JSON.stringify(owner)
      });

      // console.log(response);

      const responseJSON = await response.json();
      // console.log(responseJSON);

      if (!response.ok) return alert('failed to create group');

      const groupData = {
        members: group.members,
        ID: responseJSON.ID,
        name: responseJSON.GroupName
      }

    // Members need to be added after the group is created so that the calendar gets linked.

     const calUID = await context.dispatch('createCalendar', groupData);
    //  console.log(calUID);

    // await context.dispatch('saveGroup', );

    await context.dispatch('addUserToGroup', groupData);

    // console.log(groupData.ID); 
    // console.log(calUID);

    const userAndCalUID = {
      user: groupData.ID,
      calUID
    };

    context.dispatch('getUsersGroups', context.getters.getUser.email);


    // context.dispatch('')

    // await context.dispatch('linkCalendarToGroup', userAndCalUID);

  },

  async linkCalendarToGroup(context, userAndCalUID) {

    const apiKey = context.getters.apiKey;

    // console.log(userAndCalUID.user)
    // console.log(userAndCalUID.calUID)
    const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/LinkedCalendar/SaveLinkedCalendar?uniqueID=${userAndCalUID.user}&linkedCalUID=${userAndCalUID.calUID}`, {
      headers: {
        'Accept': 'application/json',
        'ApiKey': apiKey,
        'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
      },
      method: "POST",
    })
    if (!response.ok) console.error('failed to link calendars')
  },


    // async saveGroup(context, groupData) {
    //   const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/GetGroup?groupID=${groupData.ID}`, {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     },
    //     method: "GET",
    //   })
    //   const group = await response.json();
    //   console.log(group);
    //   console.log(groupData.members);
    //   const newResponse = await fetch(``)
    // },

    // async addUserToGroup(context, groupData) {

    //   const apiKey = context.getters.apiKey;

    //   for (let i = 0; i < groupData.members.length; i++) {
  
    //     const memberToAdd = {
    //       IsHuman: true,
    //       DisplayName: groupData.members[i],
    //       EmailAddress: groupData.members[i],
    //     }
      
    //     const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/AddUserToGroup?groupID=${groupData.ID}`, {
    //       headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'ApiKey': apiKey,
    //         'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
    //       },
    //       method: "POST",
    //       body: JSON.stringify(memberToAdd)
    //     })

    //     console.log(response);

    //     if (!response.ok) alert('failed to add user to group');

    //   }

    //   context.dispatch('getUsersGroups', context.getters.getUser.email)


    //   },




      // async getAllGroupEvents(context, groups) {

      //   const apiKey = context.getters.apiKey;

      //   groups.forEach(async group => {

      //     const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/GetOwnersCalendars?ownerEmail=${group.groupID}&includeEvents=true`, {
      //       headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json',
      //         'ApiKey': apiKey,
      //         'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
      //     },
      //       method: "GET",
      //     })
          
      //     const ownersCal = await response.json();
    
      //     console.log(ownersCal);
      //     if (ownersCal.length <= 0) return;
          
      //     const calUID = ownersCal[0].UID;
      //     console.log(ownersCal);
      //     console.log(calUID);
          
      //     context.commit('setGroupCalUID', calUID);
      //     const eventsList = [];
      
      //     const eventsArr = Object.values(ownersCal[0].Events);
    
      //     console.log(eventsArr);
      //     eventsArr.forEach(event => {
      //     let availability

      //       if (event.Class === "PUBLIC") availability = 1;
      //       else if (event.Class === "PERSONAL") availability = 2;
      //       else if (event.Class === "PRIVATE") availability = 3;

      //       eventsList.push(createCalEvent(event.Summary, ownersCal[0].OwnerEmail, event.DtStart, event.DtEnd, ownersCal[0].UID, availability));        

      //     })

      //     // context.commit('addGroupEventsToGroup', eventsList);
      //   })
      // },

  
  async getGroupEvents(context, groupID) {

        const apiKey = context.getters.apiKey;

        const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/GetOwnersCalendars?ownerEmail=${groupID}&includeEvents=true`, {
        // const response = await fetch(`${context.getters.calendarAPI}/CalendarAPI/LinkedCalendar/GetLinkedCalendars&uniqueID=${groupID}&includeEvents=true`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'ApiKey': apiKey,
            'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        },
          method: "GET",
        })

        const ownersCal = await response.json();
  
        console.log(ownersCal);
        if (ownersCal.length <= 0) return;
        
        const calUID = ownersCal[0].UID;
        console.log(ownersCal);
        console.log(calUID);
        
        context.commit('setGroupCalUID', calUID);
        const eventsList = [];
    
        const eventsArr = Object.values(ownersCal[0].Events);
        
        console.log(eventsArr);
        eventsArr.forEach(event => {
          let availability
  
          if (event.Class === "PUBLIC") availability = 1;
          else if (event.Class === "PERSONAL") availability = 2;
          else if (event.Class === "PRIVATE") availability = 3;

          let attendees;

          if (event.Attendees.length <= 0) {
          event.Attendees.forEach(attendee => {
            attendee.push(attendee.EmailIdentity.EmailAddress);
          })
        }

          // (text, userID, startDate, endDate, attendees, calUID, eventUID, typeId)

          console.log(event)
  
          eventsList.push(createCalEvent(event.Summary, ownersCal[0].OwnerEmail, event.DtStart, event.DtEnd, attendees, ownersCal[0].UID, event.UId, availability));        
  
        })

        // context.commit('setGroupEvents', eventsList);

      },

    // async getUsersGroups(context, username) {

    //   console.log('trying to get users groups..... for ' + username);

    //   const apiKey = context.getters.apiKey;

    //   const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/GetUsersGroups?uniqueName=${username}&onlyOwner=false`, {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //       'ApiKey': apiKey,
    //       'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
    //   },
    //     method: "GET",
    //   });
    //   console.log(response);
    //   const groups = await response.json();

    //   const userGroups = {};

    //   console.log("THIS IS THE USERS GROUPS (DOWN ARROW)");
    //   console.log(groups);
 
    //   groups.forEach(group => {

    //     const groupMembers = [];
    //     groupMembers.push({
    //       user: group.Owner.EmailAddress,
    //       owner: true,
    //     });

    //     group.GroupMembers.forEach(member => {
    //       groupMembers.push({
    //         user: member.User.EmailAddress,
    //         owner: false,
    //       })
    //     });

    //     userGroups[group.GroupName] = {
    //       groupID: group.ID,
    //       groupMembers,
    //       emoji: group.GroupEmoji.Unicode
    //     };
    //   });

    //   context.commit('setUsersGroups', userGroups);
    // },


    setGroupEvents(context, TTuser) {
      const allEvents = context.getters.getAllCalsAndEvents;
      console.log(allEvents);
      let selectedGroup = allEvents[TTuser.name];
      localStorage.setItem('currentCalendar', TTuser.name);

      console.log(TTuser);
      if (!selectedGroup) {
        selectedGroup = allEvents[TTuser.groupID];
      }

      context.commit('setGroupCalUID', {UID: selectedGroup?.calUID, text: TTuser.name, emoji: TTuser.emoji });
      context.commit('newSetGroupEvents', selectedGroup?.events);
    },

 
    async getGroup(context, groupID) {

      const apiKey = context.getters.apiKey;


      const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/GetGroup?groupID=${groupID}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
      },
        method: "GET",
      });

      const groupData = await response.json();

      const groupMembers = [];
      groupData.GroupMembers.forEach(member => {
        groupMembers.push(member.User.EmailAddress);
      });

      const groupOwner = groupData.Owner.EmailAddress;

      const group = {
            text: groupData.GroupName,
            id: groupData.ID,
            // color: '#9ad0df',
            discipline: groupData.GroupName,
            owner: groupOwner,
            members: groupMembers,
            allMembers: [...groupMembers, groupOwner]
          }

      context.commit('setSelectedGroup', group);
    },

    async removeUserFromGroup(context, userAndGroupID) {

      const apiKey = context.getters.apiKey;

      const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/RemoveUserFromGroup?groupID=${userAndGroupID.groupID}&userEmail=${userAndGroupID.user}`, {
        headers: {
          'Accept': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        }, 
        method: "DELETE"
      })

      if (!response.ok) alert('something went wrong');
      context.dispatch('getUsersGroups', context.getters.getUser.email);

      if (response.ok)   context.dispatch('getUsersGroups', context.getters.getUser.email);

      // context.commit('removeSelectedUserFromGroup', userAndGroupID);
    },

    async deleteGroup(context, groupID) {

      const apiKey = context.getters.apiKey;

      const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/DeleteGroup?groupID=${groupID}`, {
        headers: {
          'Accept': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        },
        method: "DELETE"
      })

      if (!response.ok) alert('failed to delete group');

      if (response.ok) context.dispatch('getUsersGroups', context.getters.getUser.email);
    },


    //////////////////////////// EVENTS //////////////////////////////////////

    async getEventsFromAPI(context, uniqueKey) {

      const apiKey = context.getters.apiKey;

      console.log(context.getters.getUser.bearerToken);


      const response = await fetch(`${context.getters.calendarAPI}/Events/${uniqueKey}`, {
        headers: {
          'Accept': 'application/json',
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        },
        method: "GET"
      })

      console.log(response);
      if (!response.ok) alert('something went wrong');
      
      const events = await response.json();

      console.log(events);

      events.eventTitle = uniqueKey;

      context.commit('newSetGroupEvents', events);

      // context.commit('setEventsFromAPI', events);
    },



    async addEventToList(context, details) {
      console.log('adding event to list');

      const apiKey = context.getters.apiKey;
      
      const response = await fetch(`${context.getters.calendarAPI}/AddEvent/${details.uniqueKey}/${details.description}/${details.startDateTime}/${details.endDateTime}`, {
        headers: {
          "Accept": "application/json",
          'ApiKey': apiKey,
          'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
        },
        method: "POST"
      });
      if (!response.ok) alert('something went wrong');

      const responseJSON = await response.json();
      console.log(responseJSON);

      // if (response.ok) context.dispatch('getEventsFromAPI', details.uniqueKey); 
    }
  } 
// end of export default.

// const constructGroup = function(ID, groupName, owner, members,)

// const groupsMenuBarCreator = function(ID, groupName, groupMembers, ) {

// },


const createCalEvent = function(text, userID, startDate, endDate, attendees, calUID, eventUID, typeId = 0) {
  return {
      text,
      userID,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      attendees,
      calUID,
      eventUID,
      typeId,
    }
  }


