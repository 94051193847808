<template>
    <div>
        <h1>CSV Data</h1>
        <table v-if="csvData.length">
            <thead>
                <tr>
                    <th v-for="header in csvData[0]" :key="header">{{ header }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in csvData.slice(1)" :key="rowIndex">
                    <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                </tr>
            </tbody>
        </table>
        <p v-else>Loading...</p>
    </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import Papa from 'papaparse';
  import { useStore } from "vuex";
  
  const csvData = ref([]);
  
  const props = defineProps({
    documentName: String,
  });
  const store = useStore();
  
  const csv = computed(() => store.getters.getDocumentById(props.documentName));
  console.log("🚀 ~ csv:", csv.value)
  // Assuming the Blob is passed directly, for example, via a prop or other means
  const csvBlob = csv.value.FileBytes
//   const csvBlob = new Blob([`id,name,age\n1,John Doe,30\n2,Jane Doe,25`], { type: 'text/csv' }); // Example Blob
  
  const parseCsvBlob = async (blob) => {
    try {
      const base64Text = await blob.text(); // Convert Blob to text
      const csvText = atob(base64Text); // Decode base64

      Papa.parse(csvText, {
        complete: (result) => {
          csvData.value = result.data;
        },
        header: false, // Set to true if your CSV has headers
      });
    } catch (error) {
      console.error('Error parsing CSV Blob:', error);
    }
  };
  
  onMounted(() => {
    parseCsvBlob(csvBlob);
  });
  </script>
  
  <style>
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f4f4f4;
  }
  </style>
  