// import auth from "../../core/controls/auth";
import store from "./store";

import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import LandingPagePreLogin from "./layouts/LandingPagePreLogin";
import Verify from "../../core/views/Auth/verify.vue";

import Tags from "../../core/components/email/TagsView.vue";
import EmailsView from "./views/EmailsView.vue";
import EmailKB from "./views/EmailsKBView.vue";
import Sync from "../../core/views/email/SyncUserEmail.vue";
import SignificantTags from "../../core/components/email/SignificantTags.vue";

import Calendar from "../../core/components/calendar/scheduler/DevExCal.vue";

import Groups from "./views/Groups-View.vue"
// import Sync from "../../core/components/email/EmailKB.vue";

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `../../core/views/Auth/${view}.vue`)
  // return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/groups",
      name: "Groups",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Groups
    },
    {
      path: "/tags",
      name: "Tags",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tags
    },
    {
      path: "/emails",
      name: "Emails",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: EmailsView
    },
    {
      path: "/calendar",
      name: "Calendar",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Calendar
    },
    {
      path: "/sync",
      name: "Sync",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Sync
    },
    {
      path: "/significanttags",
      name: "SignificantTags",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: SignificantTags
    },
    {
      path: "/emailkb",
      name: "EmailKB",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: EmailKB
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: LandingPagePreLogin,
        title: "Sign In",
        description: "This is the tagline for SensiStar"
      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: LandingPagePreLogin,
        title: "Sign Up",
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/verify",
      name: "Verify",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Verify"
      },
      component: Verify,
      props: (route) => ({ email: route.query.e, token: route.query.t })

    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: '/:catchAll(.*)',
      redirect: "/home"
    }
    // {
    //   path: "/recovery",
    //   redirect: "/home"
    // },
    // {
    //   path: "/:pathMatch(.*)*",
    //   redirect: "/home"
    // }
  ],
  history: createWebHistory()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("🚀 ~ router.beforeEach ~ store.getters.getIsUserLoggedIn:", store.getters.getIsUserLoggedIn)
    if (!store.getters.getIsUserLoggedIn) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
