// import { createStore } from 'vuex'

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// import authModule from './auth/index';
// import groups from './groups/index';

export default ({
  state() {
    return {
      emoji: '😄',
      loginModalVisible: false,
      timezone: "Europe/London",
      // timezone: "America/Los_Angeles",
      usersAndCalIDs: {},
      tymeToolUser: {
        emailAddress: "",
        calUID: "",
      },
      allCalsAndEvents: {},
      linkedEvents: [],
      // groupList: [],
      currentGroup: {

      },
      currentGroupMembers: [],
      // currentGroup: null,
      groupEvents: [ 
    ],
      personalCalendar: {
        // THIS IS USED FOR TESTING WHEN THE API IS DOWN
        members: [
          {
            text: 'zacks group',
            id: 2,
            color: '#fff',
            discipline: 'zack\'s group'
          },
        ]
      },

      uniqueKeys: ['testevents'],
      eventsFromAPI: [],

      events: []
    }
  },

  mutations,
  actions,
  getters,

  modules: {
    // authModule,
    // groups
  },
})
