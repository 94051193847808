<template>
  <div>


    <div class="flex">
      <h4 class="sig-tags-heading">Create a new:</h4>
      <SelectBoxWithIconVue :selectedValue="selectedTag" :dataSource="sigTags" @selectedItem="selectTag" />
      <div class="flex">
        <DxTextBox v-model="newTagName" :placeholder="selectedTag ? `${selectedTag} name` : ''" class="text-box"
          @onEnterKey="addNewTag" />
        <DxButton text="" icon="plus" @click="addNewTag" />
      </div>
    </div>
    <div class="flex wrap">
      <div class="relative" style="margin: 4rem" v-for="(tag, key) in ownTagList" :key="key">
        <UserAddedTag @deleteTag="deleteTag" :tag="tag" />

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import SelectBoxWithIconVue from "../UserSelectable/SelectBoxWithIcon.vue";
// import SelectTagsVue from "./SelectTags.vue";
import DxButton from "devextreme-vue/button";
import DisplayTags from "./DisplayTags.vue";
// import DxPopup from "devextreme-vue/popup";
import DxTextBox from "devextreme-vue/text-box";
import UserAddedTag from "./UserAddedTag.vue";

let ownTagList = ref([]);

const store = useStore();

const showPopup = ref(false);

let newTagName = ref("");

let sigTags = ref([
  { tag: "venture", desc: "venture" },
  { tag: "project", desc: "project" },
  { tag: "group", desc: "group" },
  // { tag: "user", desc: "user" },
  // { tag: "CalendarEvent", desc: "CalendarEvent" },
]);


const selectedTag = computed(() => store.state.Emails.tagsFilter);

function selectTag(filter) {
  store.commit("tagsFilter", filter);
}

function deleteTag(tag) {
  let index = ownTagList.value.indexOf(tag);
  console.log(index);
  store.dispatch('deleteSignificantTag', ownTagList.value[index]);
  ownTagList.value.splice(index, 1);

}


function addNewTag(tag) {
  console.log(newTagName.value);
  let newTag = {
    tag: `${selectedTag.value}`,
    ConceptApplyingTo: 8,
    Names: [`${newTagName.value}`],
    Descriptions: [],
    OwnerEmailAddress: "monim@lanternsoft.com",
  }
  ownTagList.value.push(
    newTag
  );
  store.dispatch('setSignificantTags', newTag)
}

function togglePopup() {
  showPopup.value = !showPopup.value;
}
</script>

<style>
.relative {
  position: relative;
  font-size: 150%;
}

.text-box {
  padding: -0.4rem 0 -0.1rem 0;
  margin: 0 0.3rem 0 0.3rem;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.sig-tags-heading {
  margin: 5px 10px;
}
</style>
