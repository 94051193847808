/* eslint-disable */
import actions from './actions';

export default ({
    state() {
      return {
        loggedInUser: null,
        appInContext: null,
        appsAuthorisedFor: [],
        VUE_APP_APIURL: '',
        contentAPI: '',
        calendarAPI: '',
        apiKey: '',
        healthApiURL: '',
        cashFlowAPI: '',
        modelHub: ''
      }
    },
    mutations: {
      healthApiURL(state, URL) {
        state.healthApiURL = URL;
      },
      apiKey(state, key) {
        state.apiKey = key;
      },
      calendarAPI(state, url) {
        state.calendarAPI = url;
      },
      cashFlowAPI(state, url) {
        state.cashFlowAPI = url;
      },
      modelHub(state, hub) {
        state.modelHub = hub;
      },
      contentAPI(state, url) {
        state.contentAPI = url;
      },
      VUE_APP_APIURL(state, url) {
        state.VUE_APP_APIURL = url;
      },
      setLoggedInUser(state, user) {
        state.loggedInUser = user;
      },
      setAppInContext(state, app) {
        state.appInContext = app;
      },
      setApps(state, apps) {
        state.appsAuthorisedFor = apps;
      },
      logUserOut(state) {
        state.loggedInUser = null;
        state.appInContext = null;
        localStorage.clear();
      }
    },
    getters: {
      healthApiURL(state) {
        return state.healthApiURL;
      },
      apiKey(state) {
        return state.apiKey;
      },
      calendarAPI(state) {
        return state.calendarAPI;
      },
      contentAPI(state) {
        return state.contentAPI;
      },
      cashFlowAPI(state) {
        return state.cashFlowAPI;
      },
      modelHub(state) {
        return state.modelHub;
      },
      VUE_APP_APIURL(state) {
        return state.VUE_APP_APIURL;
      },
      getUser(state) {
        return state.loggedInUser;
      },
      getIsUserLoggedIn(state) {
        // console.log(state.loggedInUser);
        return !!state.loggedInUser;
      },
      getAppInContext(state) {
        return state.appInContext;
      }
    },
    actions
})