export default [
  {
    text: "Data",
    icon: "email",
    items: [
      {
        text: "Groups",
        path: "/groups",
        icon: "group",
      },
      {
        text: "Tags",
        path: "/tags",
        icon: "smalliconslayout",
      },
      {
        text: "Emails",
        path: "/emails",
        icon: "detailslayout",
      },
      {
        text: "Knowledge Base",
        path: "/emailkb",
        icon: "hierarchy",
      },
      {
        text: "Calendar",
        path: "/calendar",
        icon: "event",
      },
    ]
  },
  {
    text: "Admin",
    icon: "folder",
    items: [
      {
        text: "Sync",
        path: "/sync",
        icon: "refresh"
      },
      {
        text: "Significant Tags",
        path: "/significanttags",
        icon: "variable"
      }
    ]
  }
  ];
