<template>
  <div  id="list-demo">
    <div class="widget-container">
      <DxList
        :data-source="componentsTags"
        :repaint-changes-only="true"
        key-expr="id"
        width="250"
        :allowItemDeleting="allowDeleting"
        noDataText="Drop tags here..."
      >
        <DxItemDragging
          data="componentsTags"
          :allow-reordering="true"
          :on-drag-start="onDragStart"
          :on-add="onAdd"
          :on-remove="onRemove"
          group="tasks"

        />
      </DxList>
    </div>
  </div>
</template>
<script>

// https://js.devexpress.com/Demos/WidgetsGallery/Demo/List/ItemDragging/Vue/Light/

import DxList, { DxItemDragging } from 'devextreme-vue/list';

export default {
  components: {
    DxList,
    DxItemDragging,
  },
  props: {
    dataSource: Object,
    allowDeleting: Boolean,
    allowReordering: Boolean,
  },

  data() {
    return {
      componentsTags: this.dataSource,
    };
  },
  methods: {
    onDragStart(e) {
      e.itemData = this[e.fromData][e.fromIndex];
    },
    onAdd(e) {
      const data = [...this[e.toData]];
      if (data.some(item => item.id === e.itemData.id)) return alert('already tagged');
      data.splice(e.toIndex, 0, e.itemData);
      this[e.toData] = data;
    },
    onRemove(e) {
      const data = [...this[e.fromData]];
      // data.splice(e.fromIndex, 1);
      this[e.fromData] = data;
    },
  }, 
};
</script>
<style >
.widget-container {
  /* display: flex; */
}

.widget-container > * {
  /* height: 400px; */
  padding: 10px;
}

.dx-scrollview-content {
  /* min-height: 380px; */
}

</style>
