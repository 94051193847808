<template>
  <div>
    <div class="tags" v-for="(tag, i) in tags" :key="i">
      <img
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :src="getIcon(tag.type)"
        alt=""
        :width="size"
        style="transition: all .4s;"
        :style="{
          transform: hover ? 'scale(1.1)' : 'scale(1)'
        }"
      />
      <span :style="`font-size: ${size / 2}px`" v-if="tag.desc">{{
        tag.desc
      }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  tags: Array,
  size: Number,
  hoverAndClickStyling: Boolean
})

let hover = ref(false)

function getIcon(modelType) {
  try{
    return require(`../../assets/icons/${modelType.toLowerCase()}.png`) //note backticks used for String interpolation
  }
  catch(error){
    console.log(modelType + ' has no icon: ' + error);
    // return  require(`../../assets/icons/email.png`);
  }
}
</script>

<style scoped lang="scss">
.tags {
  display: flex;
  align-items: center;
  position: relative;

  & img {
    margin-right: 0.5rem;
  }
}
</style>
