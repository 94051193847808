<template>
  <div>
    <!-- <button @click="decode">decodeee</button> -->
    <img v-if="imgTest" style="width: 100%" :src="imgTest" />
    <div v-else>This image is not available.</div>
  </div>
</template>

<script setup>
import { ref, watchEffect, watch, computed } from "vue";
import { useStore } from "vuex";
const props = defineProps({
  imageName: String,
});
// const documentNameComp = computed(() => props.imageName);

const store = useStore();
// const imageData = computed(() =>
//   store.getters.getDocumentById(props.imageName)
// );

let imgTest = ref(null);
const documentNameComp = computed(() => props.imageName);
watch(documentNameComp, (newVal, oldVal) => {
  console.log("🚀 ~ watch ~ documentName:", newVal);
  decode(newVal);
});
function decode() {
  imgTest.value = null
  const imageData = store.getters.getDocumentById(props.imageName);
  if (!imageData) return
  //  (imgTest.value = null);

  console.log("hesss trying");
  console.log(Object.prototype.toString.call(imageData.FileBytes));
  console.log(imageData.FileBytes);
  if (Object.prototype.toString.call(imageData.FileBytes) === "[object Blob]") {
    console.log("loadstart");
    let reader = new FileReader();
    reader.onloadend = function () {
      console.log("loadended");
      imgTest.value = reader.result;
    };
    reader.readAsDataURL(imageData.FileBytes);
  } else {
    console.error("FileBytes is not a Blob");
  }
  return null;
}

decode();
</script>

<style></style>
