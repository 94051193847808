// @ts-ignore
function reloadLoginDetailsFromLocalStorage(store) {
  const user = localStorage.getItem("loggedInUser");
  // @ts-ignore
  const userDetails = JSON.parse(user);
  store.commit("setAppInContext", localStorage.getItem("appInContext"));
  store.commit("setUserEmail", userDetails?.email);
  store.commit("setLoggedInUser", userDetails);

  return userDetails;
}
// @ts-ignore
function getAndSetRuntimeConfig(store, callback, additionalEndpoints) {
  // @ts-ignore
  const runtimeConfig = async () => {
    const runtimeConfig = await fetch("/runtimeConfig.json");
    return runtimeConfig.json();
  };

  runtimeConfig().then(function (json) {
    // for (const endpoint of additionalEndpoints) {

    // }

    const endpointsAndKeys = Object.entries(json);

    endpointsAndKeys.forEach(([key, endpoint]) => {
      store.commit(key, endpoint);
    });

    // let apiEndpoint = json.VUE_APP_APIURL;
    const apiKey = json.API_KEY;
    // if (apiEndpoint) {
    //     store.commit('contentAPI', apiEndpoint);
    //     store.commit('VUE_APP_APIURL', apiEndpoint);
    // }
    if (apiKey) store.commit("apiKey", apiKey);
    callback();
  });
}

export { reloadLoginDetailsFromLocalStorage, getAndSetRuntimeConfig };
