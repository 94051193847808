<template>
  <div>
    <h2 class="content-block">Profile</h2>

    <DxButton @click="exportData" text="Export all data"/>
    <DxButton @click="deleteAccount" text="Delete account"/>
  

 
  </div>
</template>

<script setup>
// import DxForm from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";

import { useStore } from 'vuex';

const store = useStore();

function exportData() {
  store.dispatch('getAllData')
}

function deleteAccount() {
  store.dispatch('deleteAccount')
}
    // picture: String

</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
