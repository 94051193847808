<template>
  <div>
    <div style="display: flex; align-items: center">
      <SelectBoxWithIconVue
        :selectedValue="store.state.Emails.tagsFilter"
        :dataSource="store.state.Emails.extractedTags"
        @selectedItem="addFilter"
      />
      <div>
        <div class="search-box">
          <DxTextBox
            height="44"
            width="300"
            placeholder="Search Tags..."
            @enter-key="parseArrOfStr"
            v-model="searchTerm"
          />
          <div v-if="textEntered" class="deselect-tags" @click="removeText">
            <i class="fa fa-times"></i>
          </div>
          <div class="search-btn" @click="parseArrOfStr">Search</div>
        </div>
      </div>
    </div>
    <div>
      <AGDataGrid
        @clickedRow="displayCard"
        :columnDefs="columnDefs"
        :dataSource="emails"
        :filter="store.getters.tagsFilter"
        :textFilter="searchTerm"
      />
    </div>
    <!-- <EmailCard
      :cardData="emailCardData"
      :showCard="showCard"
      @close="closeCard"
    /> -->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";



import AGDataGrid from "./AGDataGrid.vue";
import EmailCard from "./EmailCard.vue";
import SelectBoxWithIconVue from "../UserSelectable/SelectBoxWithIcon.vue";
import EmailTagger from "./EmailTagger.vue";
import { doingTasks } from "./data.js";
// import { myHub } from '../../../../core/signalr/clients/EmailDataHub'

import DxTextBox from "devextreme-vue/text-box";

import { EmailData } from "../../types/EmailTypes";

const props = defineProps({
  Emails: Array
})

const store = useStore();
// store.commit("extractTagsFromEmails", store.state.Emails.emails);
// store.commit('extractAttachmentDescriptions', store.state.Emails.emails)

const emails = computed(() => props.Emails || store.state.Emails.emails);
let setFilter = null;

// onMounted(() => {
  setTimeout(() => {

    setFilter = computed(() => store.getters.tagsFilter);
  },500)
// })

let searchTerm = ref("");
function removeText() {
  searchTerm.value = "";
}
let textEntered = computed(() => searchTerm.value.length > 0);

function addFilter(filter) {
  console.log("🚀 ~ addFilter ~ filter:", filter)
  store.commit("tagsFilter", filter);
}

let columnDefs = ref([
  { headerName: "From", field: "From" },
  { headerName: "Subject", field: "Subject" },
  // { headerName: "Body", field: "Body", cellStyle: { "white-space": "normal" } },
  { headerName: "Files", field: "Attachments.length", width: 70 },
  { headerName: "Tags", field: "TotalTags", width: 70},
  {
    headerName: "Tags",
    field: "ModelsTags",
    cellRenderer: "ModelTagsCellRenderer",
    autoHeight: true,
    // flex: 1 
  },
  { headerName: "Time", field: "SentTime", cellRenderer: "TimeDisplay", width: 70 },
]);

// myHub.on('update', (p: any, v: any) => {
//   store.dispatch('addEmail', p)
// })

// interface EmailData {
//   timeReceived: String
//   Subject: String
//   sourceAddress: String
//   attachments: String
//   bodySize: Number
// }

let showCard = ref(false);
let emailCardData = ref({});

const displayCard = (emailData: EmailData) => {
  console.log(emailData);
  emailCardData.value = emailData;
  showCard.value = true;
};

function closeCard() {
  showCard.value = false;
}

async function loadEmails() {
  let apiURL = "ventureAPI";
  const res = await fetch(`${apiURL}/getEmails`);
}

///   USED FOR TESTING WHEN NO SIGNAL R

// function addEmails
</script>

<style lang="scss" scoped>
.deselect-tags {
  color: red;
  font-size: 2rem;
  padding: 2px 0 0 2px;
  text-transform: bold;
  transform: translateY(3px);
}

.header-bar {
  background-color: #1271f7;
  // width: 100vw;
  height: 3rem;
  padding: 0rem 0 0 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & h3 {
    color: #fff;
  }
  & div {
    display: flex;
    align-items: center;
  }
}

.search-box {
  display: flex;
  align-items: center;
  padding-left: 8rem;
}

.search-btn {
  border: 1px solid grey;
  padding: 4px;
  font-size: 1.1rem;
  cursor: pointer;
}

.emaildisplay {
  display: flex;
  padding: 2rem;
}

.cardtagscontainer {
  z-index: 3;
  box-shadow: 0 0 6px 3px #2a2a2a30;
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 10rem;
  width: 17rem;
  transform: translate(-50%, -50%);
}
</style>
