<template>
  <div>
      {{ date }}
  </div>
</template>

<script setup>

let props = defineProps({
    data: Object
})

let date = new Date(props.params.value)

</script>

<style>

</style>