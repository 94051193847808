<template>
    <div>
        <EmailsVue :Emails="emails" />
    </div>
</template>

<script lang="ts" setup>
import EmailsVue from "../../../core/components/email/AGGridEmailDisplay.vue";
// import { computed } from 'vue';
import { useStore } from 'vuex';

import { createConnection } from '../../../core/signalr/clients/EmailDataHub';
const store = useStore()

const { myHub } = createConnection('http://localhost:4446', "EmailDataHub")

console.log(myHub);

myHub?.on('update', (p: any) => {

    store.dispatch('addEmail', p);

})


</script>

<style>
/* .dx-treeview-item {
  align-items: flex-start !important;
} */
</style>