
export default {
    async addUserToGroup(context, groupData) {

        const apiKey = context.getters.apiKey;
  
        for (let i = 0; i < groupData.members.length; i++) {
              
          const memberToAdd = {
            IsHuman: true,
            DisplayName: groupData.members[i],
            EmailAddress: groupData.members[i],
          }
        
          const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/AddUserToGroup?groupID=${groupData.ID}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'ApiKey': apiKey,
              'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
            },
            method: "POST",
            body: JSON.stringify(memberToAdd)
          })
  
          console.log(response);
  
          if (!response.ok) alert('failed to add user to group');
  
        }
  
        context.dispatch('getUsersGroups', context.getters.getUser.email)
    
       },

        async createGroup(context, group) {

            const apiKey = context.getters.apiKey;
        
             const user = context.getters.getUser.email;
        
             console.log(user);
        
              const owner = {
                IsHuman: true,
                DisplayName: user,
                EmailAddress: user,
                Avatar: group.avatar,
              }
          
              const response = await fetch (`${context.getters.calendarAPI}/GroupsAPI/CreateGroup?groupName=${group.groupName}&emoji=${group.emoji}`, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'ApiKey': apiKey,
                  'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
                },
                method: "POST",
                body: JSON.stringify(owner)
              });
        
              console.log(response);
        
              const responseJSON = await response.json();
              console.log(responseJSON);
        
              if (!response.ok) return alert('failed to create group');
        
              const groupData = {
                members: group.members,
                ID: responseJSON.Id,
                name: responseJSON.GroupName
              }
            
              
              // Members need to be added after the group calendar is created so that the calendar gets linked.
             const calUID = await context.dispatch('createCalendar', groupData);
             console.log(calUID);
                
            await context.dispatch('addUserToGroup', groupData);
        
            console.log(groupData.ID); 
            console.log(calUID);
        
            const userAndCalUID = {
              user: groupData.ID,
              calUID
            };
        
            context.dispatch('getUsersGroups', context.getters.getUser.email);
        
        },

        async removeUserFromGroup(context, userAndGroupID) {

            const apiKey = context.getters.apiKey;
      
            const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/RemoveUserFromGroup?groupID=${userAndGroupID.groupID}&userEmail=${userAndGroupID.user}`, {
              headers: {
                'Accept': 'application/json',
                'ApiKey': apiKey,
                'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
              }, 
              method: "DELETE"
            })
      
            if (!response.ok) alert('something went wrong');
            context.dispatch('getUsersGroups', context.getters.getUser.email);
      
            if (response.ok)   context.dispatch('getUsersGroups', context.getters.getUser.email);
      
            // context.commit('removeSelectedUserFromGroup', userAndGroupID);
          },

          async deleteGroup(context, groupID) {

            const apiKey = context.getters.apiKey;
      
            const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/DeleteGroup?groupID=${groupID}`, {
              headers: {
                'Accept': 'application/json',
                'ApiKey': apiKey,
                'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
              },
              method: "DELETE"
            })
      
            if (!response.ok) alert('failed to delete group');
      
            if (response.ok) context.dispatch('getUsersGroups', context.getters.getUser.email);
          },

          async getUsersGroups(context, username) {

            console.log('trying to get users groups..... for ' + username);

            const apiKey = context.getters.apiKey;
            console.log("🚀 ~ getUsersGroups ~ apiKey:", apiKey)

            console.log(context.getters.getUser.bearerToken)
      
            const response = await fetch(`${context.getters.calendarAPI}/GroupsAPI/GetUsersGroups?uniqueName=${username}&onlyOwner=false`, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'ApiKey': apiKey,
                'Authorization' : 'Bearer ' +  context.getters.getUser.bearerToken
            },
              method: "GET",
            });
            console.log(response);
            if (!response.ok) return console.error('failed to get groups')
            const groups = await response.json();
      
            const userGroups = {};
      
            console.log("THIS IS THE USERS GROUPS (DOWN ARROW)");
            console.log(groups);
       
            groups.forEach(group => {
      
              const groupMembers = [];
              groupMembers.push({
                user: group.Owner.EmailAddress,
                owner: true,
              });
      
              group.GroupMembers.forEach(member => {
                groupMembers.push({
                  user: member.User.EmailAddress,
                  owner: false,
                })
              });

              userGroups[group.GroupName] = {
                groupID: group.Id,
                groupMembers,
                emoji: group.GroupEmoji.Unicode
              };
      

            });
            
            console.log("🚀 ~ file: actions.js:183 ~ getUsersGroups ~ userGroups:", userGroups)
            context.commit('setUsersGroups', userGroups);
          },
}