<template>
  <div>
    <div
      :style="'background:' + groupuser.color"
      class="name"
    >
      <h2>{{ groupuser.text }}</h2>
    </div>
    <div class="avatar">
      <img class="avatar__img" :src="groupuser.data.avatar">
    </div>
    <div
      :style="'color:' + groupuser.color"
      class="info"
    >
      <b>{{ groupuser.data.discipline }}</b>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    groupuser: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="scss">



.avatar {
    width: 100px;
    float: left;
    overflow: hidden;
    position: relative;
    height: 100px;

    &__img {
      height: 50px;
    }
}

.name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.name h2 {
    color: #fff;
    text-align: right;
    padding: 0 15px 5px 15px;
    margin: 0;
}

.info {
    width: auto;
    text-align: left;
    height: 100%;
    font-size: 11pt;
    font-weight: normal;
    padding: 25px 20px;
    color: #707070;
}

.dx-color-scheme-contrast .info {
    color: #FFF;
}
</style>
